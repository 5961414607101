import { LayoutLoading } from 'components/LayoutLoading'
import { Tooltip } from 'flowbite-react'
import * as moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { getProfitOverview } from 'services/apis'
import { thisTime } from 'utils'

// ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

const fullYear = thisTime().year()
const months = moment.monthsShort()

export const EarningOverview = ({ userId }: { userId?: string }) => {
  const [isLoading, setLoading] = useState(true)
  const [year, setYear] = useState(fullYear)
  const [overview, setOverview] = useState<{ month: number; amount: number }[]>([])

  useEffect(() => {
    setLoading(true)
    getProfitOverview(`${year}`, userId)
      .then((data) => setOverview(data))
      .finally(() => setLoading(false))
  }, [year, userId])

  const topAmount = useMemo(() => {
    let top = 0
    overview.forEach((v) => {
      if (top < v.amount) top = v.amount
    })
    return top
  }, [overview])

  return (
    <>
      <LayoutLoading show={isLoading} />
      <div className="flex items-center text-xs mb-2">
        <p className="flex-1 text-gray-500 text-lg md:text-xs">Earning Overview</p>
        {[fullYear - 1, fullYear].map((y, index) => (
          <p
            key={index}
            className={`px-2 py-1 cursor-pointer rounded-md ${y == year ? 'bg-black text-white' : ''}`}
            onClick={() => setYear(y)}
          >
            {y}
          </p>
        ))}
      </div>
      <div className="flex gap-1 sm:gap-0 xl:gap-1 items-end flex-1">
        {months.map((text, index) => {
          const v = overview.find((v) => v.month == index + 1)
          return (
            <div key={index} className="flex-1 h-full flex flex-col justify-end">
              {!!v && !!topAmount && (
                <div
                  className={`${v.amount > topAmount / 2 ? 'bg-indigo-500' : 'bg-gray-200'} rounded-t-md mb-2`}
                  style={{ height: `${(v.amount / topAmount) * 75}%` }}
                />
              )}
              {!!v?.amount ? (
                <Tooltip content={`$${v.amount}`}>
                  <div className="text-2xs text-gray-600 text-center hover:cursor-pointer">{text}</div>
                </Tooltip>
              ) : (
                <div className="text-2xs text-gray-600 text-center hover:cursor-pointer">{text}</div>
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}
