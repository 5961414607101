'use client'
import { imgLeftArrow, imgRightArrow } from 'assets'
import { Image } from 'components/Image'
import { useEffect, useRef } from 'react'
export const HorzScrollContainer = ({
  children,
  size = 0,
}: {
  children: JSX.Element
  size?: number
  width?: number
}) => {
  const refContent = useRef<any>()
  const refScrollLeft = useRef<any>()
  const refScrollRight = useRef<any>()
  const scrollStepWidth = 250

  useEffect(() => {
    const tableContent: HTMLDivElement = refContent.current

    tableContent.addEventListener('scroll', onScrollListener)
    tableContent.addEventListener('resize', onScrollListener)
    window.addEventListener('resize', onScrollListener)

    return () => {
      tableContent.removeEventListener('scroll', onScrollListener)
      tableContent.removeEventListener('resize', onScrollListener)
      window.removeEventListener('resize', onScrollListener)
    }
  }, [])

  useEffect(() => onScrollListener(), [size])

  const onScrollListener = () => {
    const tableContent: HTMLDivElement = refContent.current
    const leftScroller: HTMLDivElement = refScrollLeft.current
    const rightScroller: HTMLDivElement = refScrollRight.current

    const { clientWidth, scrollWidth, scrollLeft } = tableContent

    if (scrollLeft == 0) leftScroller.style.opacity = '100%'
    else leftScroller.style.opacity = '100%'

    if (scrollLeft >= scrollWidth - clientWidth - 2) rightScroller.style.opacity = '100%'
    else rightScroller.style.opacity = '100%'
  }

  const onScroll = (offset: number) => {
    const tableContent: HTMLDivElement = refContent.current
    tableContent.scrollBy({ left: offset, behavior: 'smooth' })
  }

  return (
    <div className="relative">
      <div className="flex gap-2">
        <div onClick={() => onScroll(-1 * scrollStepWidth)} ref={refScrollLeft}>
          <Image src={imgLeftArrow} className="cursor-pointer" />
        </div>
        <div onClick={() => onScroll(scrollStepWidth)} ref={refScrollRight}>
          <Image src={imgRightArrow} className="cursor-pointer" />
        </div>
      </div>

      <div className="overflow-auto no-scrollbar" ref={refContent}>
        {children}
      </div>
    </div>
  )
}
