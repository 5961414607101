import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { Loading } from 'components/Loading'
import { itemCountPerPage } from 'config'
import { Tooltip } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { downloadS3Documents } from 'services/apis'
import { deleteArticle, getArticles } from 'services/apis/articles'
import { Button, Input2, Pagination } from 'stories/components'
import { confirm, formatTime, renderHeader, useTitle } from 'utils'

import { ArticleModal } from './ArticleModal'
import type { IArticle } from './types'

export const ManageArticlePage = () => {
  useTitle('Article Management')

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'id',
    orderDir: '-1',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [articles, setArticles] = useState<IArticle[]>([])
  const [selectedArticle, setSelectedArticle] = useState<IArticle | null>()
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  useEffect(() => {
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum
    setLoading(true)

    return getArticles({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setTotal(total)
        setArticles(data)
      })
      .finally(() => setLoading(false))
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (key: 'query' | 'orderBy' | 'orderDir' | 'pageNum', value: any, refetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const handleClickArticle = (article: IArticle) => {
    if (article.articleType === 'Article') window.open(article.link, '_blank', 'noopener')
  }

  const onAdd = () => {
    setSelectedArticle(null)
  }

  const onClose = async (updatedItem?: IArticle) => {
    if (!updatedItem) {
      setSelectedArticle(undefined)
      return
    }
    const newData = cloneDeep(articles)
    const { url } = await downloadS3Documents(updatedItem.image)
    updatedItem.image = url

    if (selectedArticle) {
      const index = newData.findIndex((v) => v.id == updatedItem.id)
      newData[index] = updatedItem
    } else newData.push(updatedItem)

    filterData(filters)
    setSelectedArticle(undefined)
  }

  const onDeleteArticle = async (item: IArticle, index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Article?
        <br />
        <span className="text-gray-600">Title: {item.title}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteArticle(item.id)
    setLoading(false)
    const newData = cloneDeep(articles)
    newData.splice(index, 1)
    setArticles(newData)
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
    })
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <p className="text-4xl font-semibold mb-10 flex gap-4 items-center">
        Article Management ({total}) {isLoading && <Loading />}
      </p>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        <Input2
          type="search"
          title="Search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <div></div>
        <div></div>
        <div className="flex justify-end">
          <Button onClick={onAdd}>+ Add</Button>
        </div>
      </div>

      <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {_renderHeader('No', false, 'no')}
              {_renderHeader('Title', false, 'title')}
              {_renderHeader('Tags', true, 'tags')}
              {_renderHeader('Type', true, 'articleType')}
              {_renderHeader('Created At', true, 'createdAt')}
              {_renderHeader('Updated At', true, 'updatedAt')}
              {_renderHeader('Actions', false, 'action')}
            </tr>
          </thead>
          <tbody className="text-[14px]">
            {articles.map((item, index: number) => {
              return (
                <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                  <td className="pl-3 py-3">{filters.pageNum * itemCountPerPage + index + 1}</td>
                  <td
                    className="px-2 py-2 font-bold text-indigo-500 cursor-pointer hover:underline"
                    onClick={() => handleClickArticle(item)}
                  >
                    #{item.title}
                  </td>

                  <td className="px-2 py-2">
                    <div className="font-bold text-indigo-500 cursor-pointer whitespace-nowrap hover:underline">
                      {item.tags}
                    </div>
                  </td>
                  <td className="px-2 py-2">{item.articleType}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatTime(item.createdAt)}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatTime(item.updatedAt)}</td>
                  <td className="px-2 py-3 w-28">
                    <div className="flex items-center gap-1">
                      <Tooltip content={`Edit Article Details`}>
                        <PencilSquareIcon
                          className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200"
                          onClick={() => setSelectedArticle(item)}
                        />
                      </Tooltip>
                      <Tooltip content={`Remove Article`}>
                        <TrashIcon
                          className="w-6 h-6 cursor-pointer text-red-600 hover-shadow1 p-1 transition-all duration-200"
                          onClick={() => onDeleteArticle(item, index)}
                        />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
      </div>
      {selectedArticle !== undefined && <ArticleModal data={selectedArticle} onClose={onClose} />}
    </div>
  )
}
