import { ChevronRightIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { TimeZone } from 'config'
import moment from 'moment-timezone'
import { LoanLink } from 'pages/Loan'
import { useEffect, useMemo, useState } from 'react'
import { getEarnings, getInvestment } from 'services/apis'
import { ButtonGroup } from 'stories/components'
import { normalizeNumber, thisTime, useTitle } from 'utils'

import { DBInvestment, DbProfit, monthsNameAbbr, ProfitStatus } from './constants'
import { InvestDetails } from './modal'

export const MyEarningsTab = () => {
  useTitle('My Earnings')

  const [pastMonth, setPastMonth] = useState<DbProfit[] | null>(null)
  const [thisMonth, setThisMonth] = useState<DbProfit[] | null>(null)
  const [activeInvests, setActiveInvests] = useState<DBInvestment[] | null>(null)
  const [year, setYear] = useState(`${thisTime().year()}`)
  const [yearlyProfits, setYearlyProfits] = useState<Record<number, DbProfit[]> | null>(null)
  const [collapsedMonth, setCollapsedMonth] = useState<string[]>([])
  const [selectedInvestmentId, setSelectedInvestmentId] = useState(0)
  const [selectedInvestment, setSelectedInvestment] = useState<DBInvestment | null>(null)
  const [loading, setLoading] = useState('')

  const thisMonthFirstDate = thisTime().date(1).hour(0).minute(0).second(0).millisecond(0).toDate().getTime()

  useEffect(() => {
    getEarnings('recent').then(({ pastMonth, thisMonth, activeInvests }) => {
      setPastMonth(pastMonth)
      setThisMonth(thisMonth)
      setActiveInvests(activeInvests)
    })
  }, [])

  useEffect(() => {
    if (!yearlyProfits) return

    const collapsed: string[] = []

    moment.months().map((v, month) => {
      if (!yearlyProfits[month]) collapsed.push(v)
    })

    setCollapsedMonth(collapsed)
  }, [yearlyProfits])

  useEffect(() => {
    setLoading('year')
    getEarnings(year)
      .then((data) => setYearlyProfits(data))
      .finally(() => setLoading(''))
  }, [year])

  useEffect(() => {
    selectedInvestmentId && getInvestment(selectedInvestmentId).then((data) => setSelectedInvestment(data))
  }, [selectedInvestmentId])

  const prevMonthTotal = useMemo(() => {
    if (!pastMonth || !pastMonth.length) return 0

    return pastMonth
      .filter((item) => item.status === ProfitStatus.Approved)
      .map((v) => {
        if (
          moment(v.investment.endDate).tz(TimeZone).toDate().getTime() < thisMonthFirstDate &&
          v.investment.penalty > 0
        )
          return Number(v.amount) - Number(v.investment.penalty)

        return Number(v.amount)
      })
      .reduce((prev, cur) => prev + cur, 0)
  }, [pastMonth])

  const thisMonthTotal = useMemo(() => {
    if (!thisMonth || !thisMonth.length) return 0

    return thisMonth
      .filter((item) => item.status === ProfitStatus.Approved)
      .map((v) => {
        if (
          moment(v.investment.endDate).tz(TimeZone).toDate().getTime() >= thisMonthFirstDate &&
          v.investment.penalty > 0
        )
          return Number(v.amount) - Number(v.investment.penalty)

        return Number(v.amount)
      })
      .reduce((prev, cur) => prev + cur, 0)
  }, [thisMonth])

  const pendingProfitTotal = useMemo(() => {
    let unrequestedInterest = 0
    let requestedInterest = 0

    if (activeInvests)
      unrequestedInterest = activeInvests
        .map((invest) => Number(invest.pendingProfit) || 0)
        .reduce((prev, cur) => prev + cur, 0)

    if (thisMonth)
      requestedInterest = thisMonth
        .filter((item) => item.status === ProfitStatus.Pending)
        .map((v) => Number(v.amount) || 0)
        .reduce((prev, cur) => prev + cur, 0)

    return unrequestedInterest + requestedInterest
  }, [activeInvests, thisMonth])

  const lastMonthPendingProfitTotal = useMemo(() => {
    if (!pastMonth || !pastMonth.length) return 0

    return pastMonth
      .filter((item) => item.status === ProfitStatus.Pending)
      .map((v) => Number(v.amount) || 0)
      .reduce((prev, cur) => prev + cur, 0)
  }, [pastMonth])

  const onCollapse = (v: string) => {
    const newCollpased = cloneDeep(collapsedMonth)

    if (newCollpased.includes(v)) {
      const removeIndex = newCollpased.findIndex((item) => item === v)

      newCollpased.splice(removeIndex, 1)
    } else newCollpased.push(v)

    setCollapsedMonth(newCollpased)
  }

  const renderProfit = (
    profit: DbProfit,
    index: number,
    prev: DbProfit,
    showPenalty: 'last' | 'this' | 'month' | '' = '',
  ) => {
    let isShow = true

    if (showPenalty === 'last')
      isShow =
        moment(profit.investment.endDate).tz(TimeZone).toDate().getTime() < thisMonthFirstDate &&
        !!profit.investment.penalty

    if (showPenalty === 'this')
      isShow =
        moment(profit.investment.endDate).tz(TimeZone).toDate().getTime() >= thisMonthFirstDate &&
        !!profit.investment.penalty

    if (showPenalty === 'month') {
      const nextMonthFirstDate = moment(profit.from)
        .add(1, 'month')
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .subtract(1, 'day')
        .toDate()
        .getTime()

      isShow =
        moment(profit.investment.endDate).tz(TimeZone).toDate().getTime() < nextMonthFirstDate &&
        !!profit.investment.penalty
    }

    return (
      <div className="pl-2" key={`profit-${profit.id}`}>
        {(index == 0 || prev.loan.id != profit.loan.id) && <LoanLink loan={profit.loan} showId={false} />}

        <div
          className="flex items-center justify-between transition-all duration-200 px-2 cursor-pointer hover:bg-stone-200 rounded-sm my-1"
          onClick={() => setSelectedInvestmentId(profit.investment.id)}
        >
          <p className="text-desc">
            #{profit.investment.id}. ${normalizeNumber(profit.investment.amount)}, {profit.investment.ytm}%/Yr
          </p>

          <div className="flex gap-2">
            {!!profit.amount && (
              <p
                className={`font-semibold text-sm ${
                  profit.status === ProfitStatus.Approved ? 'text-green-500' : 'text-gray-400'
                }`}
              >
                + $ {normalizeNumber(profit.amount, 2)}
              </p>
            )}
            {isShow && (
              <p className="font-semibold text-sm text-red-500">- $ {normalizeNumber(profit.investment.penalty)}</p>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderInvest = (invest: DBInvestment, index: number, prev: DBInvestment) => (
    <div className="pl-2" key={`invest-${invest.id}`}>
      {(index == 0 || prev.loan.id != invest.loan.id) && <LoanLink loan={invest.loan} showId={false} />}

      <div
        className="flex items-center justify-between px-2 cursor-pointer hover:bg-stone-200 rounded-sm my-1"
        onClick={() => setSelectedInvestmentId(invest.id)}
      >
        <p className="text-desc">
          #{invest.id}. $ {normalizeNumber(invest.amount)}, {invest.ytm}%/Yr
        </p>

        <p className="font-semibold text-sm text-gray-400">$ {normalizeNumber(invest.pendingProfit || 0, 2)}</p>
      </div>
    </div>
  )

  const renderMonthsEarnings = useMemo(() => {
    if (!yearlyProfits) return <></>

    return moment.months().map((v, month) => {
      let monthTotalProfit = 0

      if (yearlyProfits[month])
        yearlyProfits[month].map((profit) => {
          const nextMonthFirstDate = moment(profit.from)
            .add(1, 'month')
            .date(1)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .subtract(1, 'day')
            .toDate()
            .getTime()

          if (
            moment(profit.investment.endDate).tz(TimeZone).toDate().getTime() < nextMonthFirstDate &&
            !!profit.investment.penalty
          )
            monthTotalProfit += Number(profit.amount) - Number(profit.investment.penalty)
          else monthTotalProfit += Number(profit.amount)
        })

      return (
        <div key={v} className={`${!collapsedMonth.includes(v) && yearlyProfits[month] && 'mb-2'}`}>
          <div
            className={`flex justify-between items-center text-gray-600 border rounded-md border-gray-100 bg-gray-100 mb-2 p-1 cursor-pointer`}
            onClick={() => onCollapse(v)}
          >
            <span className="py-1 text-center w-16 text-sm shadow rounded-md text-white bg-indigo-500">
              {monthsNameAbbr[v]}
            </span>

            <div className="flex items-center gap-2">
              {monthTotalProfit !== 0 && (
                <span className={`font-bold ${monthTotalProfit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  {monthTotalProfit >= 0 ? '+' : '-'} $ {normalizeNumber(Math.abs(monthTotalProfit), 2)}
                </span>
              )}

              <ChevronRightIcon
                className={`w-4 h-4 text-indigo-500 transition-all duration-500 transform ${
                  !collapsedMonth.includes(v) && yearlyProfits[month] && 'rotate-90'
                }`}
              />
            </div>
          </div>

          <div
            className={`transition-all duration-500 overflow-hidden px-2`}
            style={{
              maxHeight: !collapsedMonth.includes(v) ? '20rem' : '0px',
            }}
          >
            {yearlyProfits[month] &&
              yearlyProfits[month].map((profit, i) => renderProfit(profit, i, yearlyProfits[month][i - 1], 'month'))}
          </div>
        </div>
      )
    })
  }, [yearlyProfits, collapsedMonth])

  return (
    <div className="relative">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <div className="bg-white border p-4 rounded-lg">
          <div className="flex justify-between items-center text-lg border-b-2 border-black">
            <p className="font-bold">Last Month</p>
            <div className="flex gap-4">
              <div>
                <p className="text-desc text-right">Earned Interest</p>
                <p className={`font-bold text-right ${prevMonthTotal >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  {prevMonthTotal >= 0 ? '+' : '-'} $ {normalizeNumber(Math.abs(prevMonthTotal), 2)}
                </p>
              </div>
              <div>
                <p className="text-desc text-right">Accrued Interest</p>
                <p className="font-bold text-right text-gray-400">
                  $ {normalizeNumber(lastMonthPendingProfitTotal || 0, 2)}
                </p>
              </div>
            </div>
          </div>
          {pastMonth && pastMonth.map((v, i) => renderProfit(v, i, pastMonth[i - 1], 'last'))}
        </div>

        <div className="bg-white border p-4 rounded-lg">
          <div className="flex justify-between items-center text-lg border-b-2 border-black gap-4">
            <p className="font-bold flex-1">This Month</p>
            <div>
              <p className="text-desc text-right">Earned Interest</p>
              <p className={`font-bold text-right ${thisMonthTotal >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {thisMonthTotal >= 0 ? '+' : '-'} $ {normalizeNumber(Math.abs(thisMonthTotal), 2)}
              </p>
            </div>
            <div>
              <p className="text-desc text-right">Accrued Interest</p>
              <p className="font-bold text-right text-gray-400">$ {normalizeNumber(pendingProfitTotal || 0, 2)}</p>
            </div>
          </div>
          {thisMonth && thisMonth.map((v, i) => renderProfit(v, i, thisMonth[i - 1], 'this'))}
          {activeInvests && activeInvests.map((v, i) => renderInvest(v, i, activeInvests[i - 1]))}
        </div>
      </div>

      <div className="bg-white border p-4 rounded-lg">
        <div className="max-w-[720px]">
          <div className="mb-2 flex items-center justify-between">
            <p className="font-bold text-lg">Yearly Earnings ({year})</p>

            <ButtonGroup
              title={[2, 1, 0].map((v) => `${thisTime().year() - v}`)}
              value={year}
              onChange={(v) => setYear(v)}
            />
          </div>
          <div className="relative">
            <LayoutLoading show={['year'].includes(loading)} />
            {renderMonthsEarnings}
          </div>
        </div>
      </div>

      {selectedInvestment && (
        <InvestDetails
          data={selectedInvestment}
          onClose={() => {
            setSelectedInvestmentId(0)
            setSelectedInvestment(null)
          }}
        />
      )}
    </div>
  )
}
