import { AccountType } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'reducers'

type tpNavigation = {
  name: string
  href: string
  visible: boolean
  allow?: AccountType[]
  className?: string
}

interface menuType {
  label: String
  visible: Boolean | undefined
  href: any
  allow?: AccountType[]
}

const draftNavigations: Record<string, tpNavigation> = {
  // about: { name: 'About Us', href: '/about_us', visible: true },
  opportunities: { name: 'Opportunities', href: '/opportunities', visible: true },

  pipeline: {
    name: 'Pipeline',
    href: '/pipeline',
    visible: false,
    allow: [AccountType.Admin, AccountType.FundingManager],
  },
  investments: {
    name: 'Investments',
    href: '/investments',
    visible: false,
    allow: [AccountType.Admin, AccountType.FundingManager],
  },
  dashboard: { name: 'My Dashboard', href: '/dashboard', visible: false },
  marketplace: { name: 'Opportunities', href: '/marketplace', visible: false },
  // properties: { name: 'My Properties', href: '/properties', visible: false },

  how_it_works: { name: 'How it Works', href: '/how_it_works', visible: true },
  // lenders: { name: 'Lenders', href: '/lenders', visible: true },
  faq: { name: 'FAQ', href: '/faq', visible: true },
  aboutus: { name: 'About Us', href: '/aboutus', visible: true },

  span1: {
    name: '',
    href: '',
    visible: true,
    className: 'flex-1',
  },
  register: {
    name: 'Sign Up',
    // href: '/',
    href: '/auth/signUp',
    visible: true,
    className:
      'px-4 bg-light-green rounded-full text-black hover:bg-white hover:text-gray-600 border hover:border-gray-300',
  },
  login: {
    name: 'Log in',
    href: '/auth/signIn',
    visible: true,
    className: 'px-4 rounded-full text-gray-900 hover:bg-white hover:text-gray-600 border hover:border-light-green',
  },
}

const draftMenus: menuType[] = [
  {
    label: 'My Profile',
    href: '/profile',
    visible: true,
  },
  {
    label: 'My Documents',
    href: '/documents',
    visible: true,
  },
  // {
  //   label: 'Linked Accounts',
  //   href: '/payment',
  //   visible: true,
  // },
  // {
  //   label: 'Sign Statistics',
  //   href: '/signStatistics',
  //   visible: true,
  // },
  {
    label: 'divider',
    href: '#',
    visible: true,
  },
  // {
  //   label: 'Import Loans',
  //   href: '/importLoans',
  //   visible: true,
  //   allow: [AccountType.Admin],
  // },
  {
    label: 'Manage Users',
    href: '/manageUsers',
    visible: true,
    allow: [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin],
  },
  {
    label: 'Manage Transactions',
    href: '/manageTransactions',
    visible: true,
    allow: [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin],
  },
  {
    label: 'Manage Investments',
    href: '/manageSignStatistics',
    visible: true,
    allow: [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin],
  },
  {
    label: 'Manage Distributions',
    href: '/manageProfits',
    visible: true,
    allow: [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin],
  },
  {
    label: 'Manage Articles',
    href: '/manageArticles',
    visible: true,
    allow: [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin, AccountType.ArticleManager],
  },
  {
    label: 'Admin Tools',
    href: '/adminTools',
    visible: true,
    allow: [AccountType.Admin, AccountType.FundingManager],
  },
  {
    label: 'divider',
    href: '#',
    visible: true,
    allow: [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin, AccountType.ArticleManager],
  },
]

export const getNavigations = () => {
  const [navigations, setNavigations] = useState({ ...draftNavigations })
  const auth = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    const draft = cloneDeep(navigations)
    const { accountType } = auth.profile

    const unauthHeaders = ['opportunities', 'register', 'login']
    unauthHeaders.forEach((key) => (draft[key].visible = !auth.isAuthenticated))

    const authHeaders = ['pipeline', 'investments', 'dashboard', 'marketplace']
    authHeaders.forEach((key) => (draft[key].visible = auth.isAuthenticated))

    if (accountType === AccountType.ArticleManager) {
      draft['dashboard'].visible = false
      draft['opportunities'].visible = false
      draft['marketplace'].visible = false
    }

    Object.keys(draft)
      .filter((key) => !!draft[key].allow)
      .forEach((key) => (draft[key].visible = draft[key].allow?.includes(accountType) || false))

    setNavigations(draft)
  }, [auth])

  return navigations
}

export const getMenus = () => {
  const [menus, setMenus] = useState(draftMenus)
  const auth = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    const draft = cloneDeep(menus)
    const { accountType } = auth.profile

    draft.forEach((menu) => {
      if (menu.allow) menu.visible = menu.allow.includes(accountType)
    })

    setMenus(draft)
  }, [auth])

  return menus
}
