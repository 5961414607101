import type { BaseFile } from 'config'

export enum LoanOrigin {
  SELF = 'self',
  COMMERCIAL = 'commercial',
}

export enum LoanStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  PAST = 'past',
  DECLINED = 'declined',
}

export const LoanStatusText: Record<string, string> = {
  [LoanStatus.PENDING]: 'Pending',
  [LoanStatus.ACTIVE]: 'Active',
  [LoanStatus.PAST]: 'Past',
  [LoanStatus.DECLINED]: 'Declined',
}

export const LoanOriginPrefixMap: Record<LoanOrigin, string> = {
  [LoanOrigin.SELF]: 'S',
  [LoanOrigin.COMMERCIAL]: 'C',
}

export const LoanPropertyTypeOptions: string[] = [
  'SFR-Detached',
  'SFR-Attached',
  'Condo',
  '2-4 Units',
  'PUD',
  'Mixed-Use',
  'MF (5-8 units)',
  'MF (9-30 units)',
  'MF (31-100 units)',
  'Industrial',
  'Manufactured',
  'Self Storage',
  'Office',
  'Retail',
  'Warehouse',
  'Automotive',
]

export const LoanPurposeOptions: string[] = [
  'Bridge',
  'Fix and Flip',
  'Ground Up Construction',
  'DSCR (Long Term Rental)',
]

export interface LoanDocument extends BaseFile {
  category: string
  originalKey?: string
}

export interface Loan {
  id: number

  title?: string
  lat: number
  lng: number
  description?: string
  progress: number // (funds + 1) / loanAmount * 100
  constructionBalance: number
  funds: number
  investors: number
  closed?: boolean // true if CurrentDate - maturityDate < 31

  gurantee?: string
  note: string
  origin: LoanOrigin
  loanNumber: number
  fciLoanNumber: string
  fciLoan?: {
    loanNumber: string
    status: string
    nextDueDate: string // "04/01/2024"
  }
  apnNo: string
  originalDebtor: string
  originalRecordingDate: string
  originalInstrumentNumber: string
  originalCounty: string
  originalState: string
  originalRecordingInformation: boolean

  status: LoanStatus
  isFeatured: boolean
  canInvest: boolean

  isInvested?: boolean

  propertyAddress: string
  ytm: number
  ltv: number
  loanAmount: number
  day1LoanAmount: number

  images: BaseFile[]

  documents: LoanDocument[]

  /**
   * Investment Overview
   */
  isInterestOnly: boolean
  lienType: string
  originationDate: string

  // Purpose & Strategy
  loanPurpose: string
  rehabBudget: number
  loanStrategy: string
  cashIntoClosing: number
  propertyPurchasePrice: number
  constructionReserveRemaining: number

  // Debt Stack
  // totalSeniorPositions: number // = loanAmount

  // Leverage Details
  // firstLienAmount: number // = loanAmount
  asIsLTV: number
  // purchasePrice: number // = propertyPurchasePrice
  afterRepairLTV: number
  asIsValue: number
  ltc: number
  afterRepairValue: number
  pariPassu: number
  yldInvestment: number
  subordinatePos: number

  /**
   * Valuation Table
   */
  // valuations: Record<string, any>[] // {propertyAddress, appraisalDate, asIsValue, arv}
  appraisalDate: string

  /**
   * Payment Term
   */
  originalLoanTerm: number
  maturityDate: Date
  borrowerExtension: string
  // prepayable: boolean
  prepaymentPenalty: string
  // lockOut: string

  /**
   * Borrower
   */
  borrowerType: string
  borrower: string
  creditScore: number
  // guarantor: string
  trackRecord: number

  /**
   * Property Details/Overview
   */
  propertyType: string
  squareFeet: number
  afterRehabSQFT: number
  bedrooms: number
  bathrooms: number
  lotSize: number

  updatedAt: Date

  approvedProfit?: number
  pendingProfit?: number
  totalInvestor?: number
  activeInvestor?: number

  notYetRecorded: string
}
