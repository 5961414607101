import {
  API_APPROVE_SELECTED_DISTRIBUTION,
  API_CHECK_REPORT,
  API_PAY_ALL_PROFIT,
  API_PAY_ALL_TRANSACTION,
  API_PAY_BALANCE,
  API_PAY_EARNINGS,
  API_PAY_HISTORY,
  API_PAY_MANUAL_TRANSACTION_BYID,
  API_PAY_MONTH_ESTIMATE,
  API_PAY_PLAID_CLIENT_TOKEN,
  API_PAY_PLAID_INSTITUTION,
  API_PAY_PLAID_TRANSFER,
  API_PAY_PROFIT,
  API_PAY_PROFIT_BYID,
  API_PAY_PROFIT_OVERVIEW,
  API_PAY_PROFITS_BY_INVESTID,
  // API_PAY_TRANSACTION,
  API_PAY_TRANSACTION_BYID,
  API_PAY_WIRE_TRANSFER,
  API_USER_IDENTITY_VERIFICATION_DATA,
} from 'config'
import type { TransactionMethod } from 'pages/Payment'
import type { Institution, TransferStatus } from 'plaid'
import Api from 'services/api'

export const getPlaidClientToken = (
  type: 'kyc' | 'bank' | 'withdraw' | 'deposit',
  data: Record<string, any> = {},
): Promise<{ link_token: string; expiration: string; [key: string]: any }> => {
  return Api.post(API_PAY_PLAID_CLIENT_TOKEN, data, { type })
}

export const getPlaidIdentityVerificationData = (id: number) => {
  return Api.get(API_USER_IDENTITY_VERIFICATION_DATA, {}, { id })
}

export const getPlaidInstitution = (routingNumber: string): Promise<{ institution: Institution | null }> => {
  return Api.get(API_PAY_PLAID_INSTITUTION, {}, { routingNumber })
}

export const transferWire = (data: Record<string, any>, direction: string) => {
  return Api.post(API_PAY_WIRE_TRANSFER, data, { direction })
}

export const transferPlaid = (data: Record<string, any>) => {
  return Api.post(API_PAY_PLAID_TRANSFER, data)
}

// export const getTransactions = (filters: Record<string, any>) => {
//   return Api.get(API_PAY_TRANSACTION, filters)
// }

export const getBalances = (needUpdate: boolean = false, userId: string = '') => {
  if (userId) return Api.get(API_PAY_BALANCE, { needUpdate }, { userId })
  return Api.get(API_PAY_BALANCE, { needUpdate })
}

export const getProfits = (filters: Record<string, any>) => {
  return Api.get(API_PAY_PROFIT, filters)
}

export const checkProfitReport = (filters: Record<string, any>) => {
  return Api.get(API_CHECK_REPORT, filters)
}

export const getProfitOverview = (year: string, userId: string | undefined = undefined) => {
  if (userId) return Api.get(API_PAY_PROFIT_OVERVIEW, { year }, { userId })
  return Api.get(API_PAY_PROFIT_OVERVIEW, { year })
}

export const getHistories = (filters: Record<string, any>) => {
  return Api.get(API_PAY_HISTORY, filters)
}

export const getEarnings = (year: number | string) => {
  return Api.get(API_PAY_EARNINGS, {}, { year })
}

export const getThisMonthEstimate = (userId: string | undefined = undefined) => {
  if (userId) return Api.get(API_PAY_MONTH_ESTIMATE, {}, { userId })
  return Api.get(API_PAY_MONTH_ESTIMATE)
}

/**
 * Admin
 */
export const findTransactions = (filters: Record<string, any>) => {
  return Api.get(API_PAY_ALL_TRANSACTION, filters)
}

export const getTransactionById = (id: number) => {
  return Api.get(API_PAY_TRANSACTION_BYID, {}, { id })
}

export const addTransactionEvent = (
  id: number,
  data: { status: TransferStatus; reason: string | null; method: TransactionMethod },
) => {
  return Api.put(API_PAY_TRANSACTION_BYID, data, { id })
}

export const findProfits = (filters: Record<string, any>) => {
  return Api.get(API_PAY_ALL_PROFIT, filters)
}

export const updateProfit = (id: number, data: Record<string, any>) => {
  return Api.put(API_PAY_PROFIT_BYID, data, { id })
}

export const approveSelectedDistribution = (ids: number[]) => {
  return Api.post(
    API_APPROVE_SELECTED_DISTRIBUTION,
    { ids },
    {},
    {
      timeout: 300000,
    },
  )
}

export const getDistributions = (loanId: number, investId: number) => {
  return Api.get(API_PAY_PROFITS_BY_INVESTID, {}, { loanId, investId })
}

export const createManualTransaction = (data: Record<string, any>) => {
  return Api.post(API_PAY_MANUAL_TRANSACTION_BYID, data, { id: '' })
}
