import { LoanDetails } from 'pages/Dashboard/Invests'
import type { DBInvestment } from 'pages/Payment'
import { useState } from 'react'
import { declineInvestment, getEnvelopeUrl } from 'services/apis'
import { Button, Modal } from 'stories/components'
import { thousandSeperator } from 'utils'

export const UnsignedInvestmentModal = ({ invest, onClose }: { invest: DBInvestment; onClose: Function }) => {
  const [action, setAction] = useState('')

  const onOpenDocument = () => {
    setAction('sign')
    getEnvelopeUrl(invest.loan.id as number, invest.envelopeId)
      .then(({ url }) => window.open(url, '_self'))
      .finally(() => setAction(''))
  }

  const onDecline = () => {
    setAction('decline')
    declineInvestment(invest.id)
      .then(() => {
        onClose(true)
      })
      .finally(() => setAction(''))
  }

  return (
    <Modal title="" titleOkay="" titleCancel="" onClose={() => onClose(false)} isOpen>
      <div className="lg:w-[33rem]">
        <p className="text-md text-center mb-4">
          You have not completed signing investment documents for the following opportunities. Please complete your
          investment.
        </p>

        <div className="flex justify-center mb-4">
          <LoanDetails loan={invest.loan} />
        </div>

        <p className="mb-4 text-center text-gray-400">
          Invest Amount:{' '}
          <span className="text-green-400 text-lg font-semibold">${thousandSeperator(invest.amount)}</span>
        </p>

        <div className="w-full pt-4">
          <div className="flex flex-col w-72 mb-1 mx-auto">
            <Button
              color="indigo"
              loading={action == 'sign'}
              disabled={!!action}
              full
              className="font-medium py-2 px-6"
              onClick={onOpenDocument}
            >
              Return to sign document
            </Button>
            <Button
              color="red"
              link
              loading={action == 'decline'}
              disabled={!!action}
              full
              className="font-medium py-2 px-6 text-red-500 hover:text-red-700"
              onClick={onDecline}
            >
              Decline document.
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
