import { CheckCircleIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { Loading } from 'components/Loading'
import { AccountTypeText, itemCountPerPage, ManagerAccountTypes } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { deleteTask, findTasks } from 'services/apis'
import { Button, ButtonGroup, Input2, MultiSelect, Pagination, Select2, Toggle } from 'stories/components'
import { confirm, dateDiffDays, filterObject, formatDate, formatTime, renderHeader, useTitle } from 'utils'

import { MarkTaskModal, TaskDetails, TaskStatusModal } from './modal'
import { DbTask, renderDescription, TaskStatus, TaskType, TaskTypeText } from './type'

const taskTypeOptions = {
  active: 'My Active Tasks',
  completed: 'My Completed Tasks',
}

const allTaskTypeOptions = {
  allActive: 'All Active Tasks',
  allCompleted: 'All Completed Tasks',
}

export const TaskPage = () => {
  useTitle('Task Management')

  const [searchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const defaultQuery = query.replace(/_/g, '#')
  const defaultTaskType = searchParams.get('taskType') || 'active'

  const { profile } = useSelector((state: RootState) => state.auth)
  const { accountType } = profile
  const [filters, setFilters] = useState<Record<string, any>>({
    query: defaultQuery,
    type: 'all',
    status: ['active', 'allActive'].includes(defaultTaskType) ? 'active' : 'complete',
    assignedTo: { [accountType]: true },
    orderBy: 'id',
    orderDir: '-1',
    pageNum: 0,
    deleted: false,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<DbTask[]>([])
  const [selectedItem, setSelectedItem] = useState<DbTask | null | undefined>()
  const [markItem, setMarkItem] = useState<DbTask | null>(null)
  const [statusItem, setStatusItem] = useState<DbTask | null>(null)
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [assignedToList, setAssignedToList] = useState<Record<string, string>>({})
  const [taskType, setTaskType] = useState(defaultTaskType)

  useEffect(() => {
    const newFilters = cloneDeep(filters)
    newFilters.query = defaultQuery
    setFilterQuery(defaultQuery)
    setFilters(newFilters)

    onTaskType(defaultTaskType, newFilters)
  }, [defaultTaskType, defaultQuery])

  useEffect(() => {
    window.scrollTo(window.scrollX, 0)

    const assignedToList: Record<string, string> = {}
    ManagerAccountTypes.forEach((v) => (assignedToList[v] = AccountTypeText[v]))
    setAssignedToList(assignedToList)

    const newFilters = Object.assign({}, filters)
    newFilters.assignedTo = {}
    if (!taskType.includes('all')) {
      ManagerAccountTypes.forEach((v) => (newFilters.assignedTo[v] = v == accountType))
    }

    filterData(newFilters).then(() => setIsGetUsersOnce(true))
    setFilters(newFilters)
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (filters?.query) filters.query = filters.query.trim()

    setLoading(true)
    return findTasks({
      ...filters,
      type: !filters.type || filters.type == 'all' ? null : filters.type,
      status: filters.status || null,
      assignedTo: filterObject(filters.assignedTo),
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setTotal(total)
        setData(data)
      })
      .finally(() => setLoading(false))
  }

  const onAdd = () => {
    setSelectedItem(null)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onTaskType = (taskType: string, nFilters: Record<string, any> = filters) => {
    setTaskType(taskType)
    taskType = taskType.toLowerCase()
    const newFilters = Object.assign({}, nFilters)
    newFilters.status = taskType.includes('active') ? 'active' : 'complete'
    newFilters.assignedTo = {}
    if (!taskType.includes('all')) {
      ManagerAccountTypes.forEach((v) => (newFilters.assignedTo[v] = v == accountType))
    }
    setFilters(newFilters)
    filterData(newFilters)
  }

  const onChangeFilter = (
    key: 'query' | 'type' | 'status' | 'assignedTo' | 'deleted' | 'orderBy' | 'orderDir' | 'pageNum',
    value: any,
    refetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const onComplete = async (index: number) => {
    const task = data[index]
    setMarkItem(task)
  }

  const onDelete = async (index: number) => {
    const taskId = data[index].id
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this task?
        <br />
        <span className="text-gray-600">Task ID: #{taskId}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    deleteTask(taskId)
      .then(() => {
        toast('The task has been deleted.', { type: 'success' })
        filterData(filters)
      })
      .catch(() => setLoading(false))
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
    })
  }

  const renderStatus = (item: DbTask) => {
    let bgColor = item.status == TaskStatus.ACTIVE ? 'bg-blue-500' : 'bg-green-500'
    let textStatus = item.status as string
    if (item.dueDate && item.status == TaskStatus.ACTIVE && dateDiffDays(item.dueDate)! < 0) {
      bgColor = 'bg-red-400'
      textStatus = 'overdue'
    }

    return (
      <span className={`p-2 ${bgColor} text-white rounded-lg mb-2 cursor-pointer`} onClick={() => setStatusItem(item)}>
        {textStatus}
      </span>
    )
  }

  const renderType = (item: DbTask) => {
    let linkTo = ''

    const showRefId = !!item.refId
    switch (item.type) {
      case TaskType.Plaid:
      case TaskType.Wire:
        linkTo = `/manageTransactions?query=_${item.refId}`
        break
      case TaskType.Kyc:
        linkTo = `/manageUsers?query=${item.creator}`
        break
      case TaskType.Invest:
        linkTo = `/manageSignStatistics?query=_${item.refId}`
        break
      case TaskType.Distribution:
        linkTo = `/manageProfits?query=_${item.refId}`
        break
      case TaskType.User:
        linkTo = `/manageUsers?query=_${item.refId}`
        break
      case TaskType.InvestReady: {
        const descObj = JSON.parse(item.description)
        const certId = descObj[descObj.length - 1][1]
        linkTo = `/manageUsers?query=_${item.refId}&investready=${certId}`
        break
      }
      case TaskType.TaxCert: {
        const descObj = JSON.parse(item.description)
        const certId = descObj[descObj.length - 1][1]
        linkTo = `/manageUsers?query=_${item.refId}&taxCert=${certId}`
        break
      }
      default:
        return TaskTypeText[item.type]
    }

    return (
      <div className="font-bold text-indigo-500 cursor-pointer hover:underline capitalize">
        <Link to={linkTo}>
          {item.type} {showRefId ? `#${item.refId}` : ''}
        </Link>
      </div>
    )
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <p className="text-4xl font-semibold mb-10 flex gap-4 items-center">
        Task Management <span className="text-lg">({total})</span> {isLoading && <Loading />}
      </p>

      <div className="h-fit mb-3">
        <ButtonGroup
          title={{ ...taskTypeOptions, ...allTaskTypeOptions }}
          value={taskType}
          onChange={(value) => onTaskType(value)}
        />
      </div>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        <Input2
          type="search"
          title="Search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <Select2
          id="type"
          title="Type"
          options={TaskTypeText}
          value={filters.type}
          hasDefaultOption
          defaultOptionText="All"
          onChange={(value) => onChangeFilter('type', value)}
        />
        <MultiSelect
          id="assigned"
          title="Assigned To"
          options={assignedToList}
          value={filters.assignedTo}
          hasDefaultOption
          defaultOptionText="All"
          onChange={(value) => onChangeFilter('assignedTo', value)}
        />
        <div className="flex justify-between items-center">
          <Toggle
            id="show-deleted"
            title="Trash"
            value={filters.deleted}
            onChange={(value) => onChangeFilter('deleted', value)}
          />
          <Button onClick={onAdd}>+Add</Button>
        </div>
      </div>
      <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {_renderHeader('No', false, 'no')}
              {_renderHeader('TaskID', true, 'id')}
              {_renderHeader('Type', true, 'type')}
              {_renderHeader('title', true, 'title')}
              {_renderHeader('Description', false, 'description')}
              {_renderHeader('Assigned To', false, 'assignedTo')}
              {_renderHeader('Status', true, 'status')}
              {_renderHeader('Due Date', true, 'dueDate')}
              {_renderHeader('Created', true, 'createdAt')}
              {_renderHeader('Action', false, 'action')}
            </tr>
          </thead>
          <tbody className="text-[14px]">
            {data.map((item, index: number) => {
              return (
                <tr key={index} className={`border-b ${index % 2 == 0 && 'bg-slate-50'} text-gray-900`}>
                  <td className="pl-3 py-3">{filters.pageNum * itemCountPerPage + index + 1}</td>
                  <td
                    className="px-2 py-2 font-bold text-indigo-500 cursor-pointer hover:underline"
                    onClick={() => setSelectedItem(item)}
                  >
                    #{item.id}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">{renderType(item)}</td>
                  <td className="px-2 py-2">{item.title}</td>
                  <td
                    className="px-2 py-2"
                    dangerouslySetInnerHTML={{ __html: renderDescription(item.description) }}
                  ></td>
                  <td
                    className="px-2 py-2 capitalize"
                    dangerouslySetInnerHTML={{ __html: item.assignedTo.map((v) => AccountTypeText[v]).join('<br />') }}
                  ></td>
                  <td className="px-2 py-2 capitalize">
                    {renderStatus(item)}
                    <p className="text-xs mt-2 whitespace-nowrap">
                      {item.status == TaskStatus.COMPLETE ? formatTime(item.completedDate) : ''}
                    </p>
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatDate(item.dueDate)}</td>
                  <td className="px-2 py-2">
                    <p className="whitespace-nowrap">{formatTime(item.createdAt)}</p>
                    <p>{item.creator}</p>
                  </td>
                  <td className="px-2 py-3">
                    <div className="flex items-center">
                      <CheckCircleIcon
                        className="w-6 h-6 p-1 cursor-pointer text-blue-600 hover-shadow1"
                        onClick={() => onComplete(index)}
                      />
                      <TrashIcon
                        className="w-6 h-6 p-1 cursor-pointer text-red-600 hover-shadow1"
                        onClick={() => onDelete(index)}
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
      </div>

      {selectedItem !== undefined && (
        <TaskDetails
          data={selectedItem}
          assignedToList={assignedToList}
          onClose={(isUpdated: boolean) => {
            setSelectedItem(undefined)
            isUpdated && filterData(filters)
          }}
        />
      )}
      {markItem && (
        <MarkTaskModal
          task={markItem}
          onClose={(isUpdated: boolean) => {
            setMarkItem(null)
            isUpdated && filterData(filters)
          }}
        />
      )}
      {statusItem && <TaskStatusModal task={statusItem} onClose={() => setStatusItem(null)} />}
    </div>
  )
}
