import { XMarkIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { PlainInput } from 'components/PlainInput'
import { useState } from 'react'
import { updateUserData } from 'services/apis'

import type { User } from './type'

const inputKeys: Record<string, string> = {
  address: `Company Address`,
  taxId: `Company Tax ID`,
  state: `State and date of incorporation`,
  purchaserName: `Name of other business entity Purchaser`,
  buzName: `Name of other business entity:`,
  organization: `State and date of organization:`,
  fiscal: `Fiscal year end:`,
  principal: `Principal place of business:`,
  phoneNumber: `Phone number of business:`,
  netWorth: `What is the entity’s net worth, on a consolidated basis, according to its most recent audited financial statement?`,
}

export const CompanyDetails = ({
  user,
  onUpdate: _onUpdate,
  onClose,
}: {
  user: User
  onUpdate: Function
  onClose: Function
}) => {
  const [loading, setLoading] = useState(false)

  const onUpdate = (key: string, value: string) => {
    _onUpdate(key, value)
    setLoading(true)
    updateUserData(user.id, {
      companyDetails: {
        ...user.companyDetails,
        [key]: value,
      },
    }).finally(() => setLoading(false))
  }

  return (
    <tr>
      <td className="w-12"></td>
      <td colSpan={6} className="px-2">
        <div className="relative my-2 border py-2 px-3 rounded bg-white">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold mb-2 flex items-center gap-2">
              <span className="text-[16px] ml-2">Business Details of this Company</span>
              {loading && <Loading />}
            </h2>
            <span
              className="text-sm flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
              onClick={() => onClose()}
            >
              Close
              <XMarkIcon className="w-4 h-4" />
            </span>
          </div>
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-3 py-3 w-12">No</th>
                <th className="px-2 py-3 w-[50%]">Title</th>
                <th className="px-2 py-3">Content</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(inputKeys).map((key, index) => (
                <tr key={key} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                  <td className="pl-3 py-2">{index + 1}</td>
                  <td className="p-2">{inputKeys[key]}</td>
                  <td>
                    <PlainInput
                      value={user.companyDetails[key] || ''}
                      content={user.companyDetails[key] || ''}
                      onChange={(value: string) => onUpdate(key, value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </td>
      <td className="w-28"></td>
    </tr>
  )
}
