import cloneDeep from 'clone-deep'
import type { CustomInput, InputType } from 'config'
import { useEffect, useState } from 'react'
import { createArticle, updateArticle } from 'services/apis/articles'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import { articleType, type IArticle } from './types'

export const ArticleModal = ({ data, onClose }: { data: IArticle | null; onClose: Function }) => {
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (!data) return
    const newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => (data as any)[key] && (newInputs[key].value = (data as any)[key] || ''))
    updateVisibility(newInputs)
    setInputs(newInputs)
  }, [])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)

    newInputs[key].error = ''
    if (key === 'articleType') updateVisibility(newInputs)

    setInputs(newInputs)
  }

  const updateVisibility = (newInputs: Record<string, InputType>) => {
    const articleTypeVal = newInputs.articleType.value
    newInputs['link'].visible = articleTypeVal === articleType.ARTICLE
    newInputs['description'].visible = articleTypeVal === articleType.BLOG
    newInputs['pageTitle'].visible = articleTypeVal === articleType.BLOG
    newInputs['metaDescription'].visible = articleTypeVal === articleType.BLOG
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])

      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value || ''
    })
    setInputs(newInputs)
    if (hasError) return false

    delete values.imagePreview

    setLoading(true)
    if (!data)
      createArticle(values)
        .then((newData) => onClose(newData))
        .catch(() => setLoading(false))
    else
      updateArticle(data.id, values)
        .then((newData) => onClose(newData))
        .catch(() => setLoading(false))
  }

  const renderPhotoPreview = () => {
    const image = inputs.image.value
    if (!image) return <div />
    const link = typeof image == 'string' ? data?.imageURL : URL.createObjectURL(image)
    return <img src={link} className="w-80 h-40 rounded-lg border-gray-200 border-4 object-cover" />
  }

  return (
    <Modal
      isOpen
      loading={isLoading}
      onClose={() => onClose()}
      onOk={onSubmit}
      title={`${data ? 'Update' : 'Create'} Article`}
      titleOkay="Submit"
      titleCancel="Close"
    >
      <div className="w-196 flex flex-col gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((key) => {
            const input = inputs[key]
            if (input.visible === false) return null
            if (key == 'imagePreview') (input as CustomInput).render = renderPhotoPreview

            return (
              <div className={input.span ? `col-span-${input.span}` : ''}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
