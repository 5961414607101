import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { TransactionColorMap } from 'components/TransactionStatus'
import { itemCountPerPage, PenaltyPercent } from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { LoanLink } from 'pages/Loan'
import type { Loan } from 'pages/Marketplace'
import { remainingLoanTerm } from 'pages/Marketplace/InvestCard'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { declineInvestment, getEnvelopeUrl, getInvestments, signSellBackInvestment } from 'services/apis'
import { ButtonGroup, ResponsiveTable } from 'stories/components'
import { confirm, formatDate, monthlyProfit, normalizeNumber, toS3Link } from 'utils'

import { type DBInvestment, InvestStatus } from '../Payment/constants'
import { InvestDetails } from '../Payment/modal'
import { DistributionsModal } from './DistributionsModal'

interface IProps {
  userId?: string
  userName?: string
}

export const LoanDetails = ({ loan }: { loan: Loan }) => {
  return (
    <div className="flex flex-col lg:flex-row gap-x-4 gap-y-2 lg:items-center">
      <div className="w-full aspect-video lg:aspect-square lg:w-32 lg:h-32 object-cover rounded-lg relative">
        <Image
          src={toS3Link(loan.images)}
          className="w-full aspect-video lg:aspect-square lg:w-32 lg:h-32 object-cover rounded-lg"
        />
      </div>
      <div className="flex flex-col gap-1">
        <Tooltip content="Go to Loan Investment">
          <LoanLink loan={loan} simple />
        </Tooltip>
        <p className="flex justify-between lg:justify-start font-medium items-center gap-2">
          <span className="text-desc">Annual Yield:</span>
          <span className="text-green-400 font-semibold">{loan.ytm}%</span>
        </p>

        <p className="flex justify-between lg:justify-start font-medium items-center gap-2">
          <span className="text-desc">Maturity Date:</span> {formatDate(loan.maturityDate)}
        </p>

        <p className="flex justify-between lg:justify-start font-medium items-center gap-2">
          <span className="text-desc">Borrower Next Due Date:</span>
          {loan.fciLoan ? formatDate(loan.fciLoan.nextDueDate) : 'N/A'}
        </p>
        <p className="flex justify-between lg:justify-start font-medium items-center gap-2 capitalize">
          <span className="text-desc">Loan Status:</span> {loan.fciLoan ? loan.fciLoan.status : 'N/A'}
        </p>
      </div>
    </div>
  )
}

export const InvestTable = ({
  filters,
  invests: data,
  refetch,
  isAdmin = false,
}: {
  filters: Record<string, any>
  invests: DBInvestment[]
  refetch: Function
  isAdmin?: boolean
}) => {
  const [invests, setInvests] = useState<DBInvestment[]>([])
  const [selectedItem, setSelectedItem] = useState<DBInvestment | null>(null)
  const [selectedInvest, setSelectedInvest] = useState<DBInvestment | undefined>(undefined)
  const [retradeId, setRetradeId] = useState(0)
  const [declineId, setDeclineId] = useState(0)

  useEffect(() => {
    setInvests(data)
  }, [data])

  const onOpenItem = (index: number) => {
    setSelectedItem(invests[index] || null)
  }

  const onSort = (orderBy: string) => {
    if (!orderBy) return

    const newFilters = Object.assign({}, filters)

    if (newFilters.orderBy == orderBy) newFilters.orderDir = 0 - Number(newFilters.orderDir)

    newFilters.orderBy = orderBy

    refetch(newFilters)
  }

  const onSellBack = async (data: DBInvestment, event: React.MouseEvent<any>) => {
    event.preventDefault()

    const result = await confirm(
      <p className="mb-4">
        There is a{' '}
        <b>
          {100 * PenaltyPercent}% ($ {normalizeNumber(data.amount * PenaltyPercent)})
        </b>{' '}
        transaction fee to exit the investment prior to payoff. Please confirm you wish to proceed.
      </p>,
    )
    if (!result) return

    setRetradeId(data.id)
    signSellBackInvestment(data.id)
      .then(({ redirectUrl }) => window.open(redirectUrl, '_self'))
      .catch(() => setRetradeId(0))
  }

  const onDecline = async (data: DBInvestment, event: React.MouseEvent<any>) => {
    event.preventDefault()

    const result = await confirm(
      <p className="mb-4">Are you sure to decline signing documents for investment #{data.id}?</p>,
    )
    if (!result) return

    setDeclineId(data.id)
    await declineInvestment(data.id)
    const newInvests = cloneDeep(invests)
    newInvests.forEach((item) => {
      if (item.id === data.id) item.status = InvestStatus.Cancelled
    })
    setInvests(newInvests)
    setDeclineId(0)
  }

  const onOpenDocument = (invest: DBInvestment, event: React.MouseEvent<any>) => {
    event.preventDefault()

    getEnvelopeUrl(invest.loan.id as number, invest.envelopeId)
      .then(({ url }) => window.open(url, '_self'))
      .catch((err) => console.log(err))
  }

  return (
    <>
      <ResponsiveTable
        header={[
          'Invest<br />No',
          'Loan Detail',
          'Invest<br />Status',
          'Amount',
          'Monthly<br />Interest',
          'Term<br />Remaining',
          'Investment<br />Date',
          'Earnings',
        ]}
        headerKeys={['id', '', 'status', 'amount', '', '', 'startDate', '']}
        showKeys={[true, false, true, true, true, true, true, true, true, true]}
        onClickHeader={(v: string) => onSort(v)}
        headerClassName="hidden lg:grid lg:text-xs"
        containerClassName={`text-sm rounded-lg overflow-hidden gap-2`}
        contentClassName={`lg:grid grid-cols-12 text-sm py-2 lg:py-3 cursor-pointer border rounded-lg`}
        size={[1, 4, 1, 1, 1, 1, 1, 1]}
        orderBy={filters.orderBy}
        orderDir={filters.orderDir}
        onClickItem={onOpenItem}
        data={invests.map((item, index) => [
          <div className="font-bold text-indigo-500 hover:underline cursor-pointer pl-3" key={index}>
            #{item.id}
          </div>,
          <LoanDetails loan={item.loan} />,
          <div className="text-right">
            <div className="flex gap-2 items-center justify-end lg:justify-start">
              {isAdmin ? (
                <>
                  <span className={`w-2 h-2 rounded-full ${TransactionColorMap[item.status]}`} />
                  <span className="capitalize">{item.status}</span>
                </>
              ) : item.status === InvestStatus.Pending ? (
                <>
                  <span className={`w-2 h-2 rounded-full ${TransactionColorMap[item.status]}`} />
                  <Tooltip
                    content={
                      <div className="w-[80vw] xs:w-100 relative text-left">
                        <p className="mb-2">Please complete the documents to finalize your investment.</p>
                        <div className="flex justify-end">
                          <button
                            className="px-2 py-1.5 border border-gray-400 bg-transparent rounded text-white hover:cursor-pointer transition-all duration-200 hover:text-gray-900 hover:bg-gray-200"
                            onClick={(e) => onOpenDocument(item, e)}
                          >
                            Return to sign document
                          </button>
                        </div>
                      </div>
                    }
                  >
                    <span className="capitalize">{item.status}</span>
                  </Tooltip>
                </>
              ) : (
                <>
                  <span className={`w-2 h-2 rounded-full ${TransactionColorMap[item.status]}`} />
                  <span className="capitalize">{item.status}</span>
                </>
              )}
            </div>

            {!isAdmin && (
              <>
                {item.status == InvestStatus.Settled &&
                  (retradeId == item.id ? (
                    <Loading />
                  ) : (
                    <Tooltip
                      content={
                        <div className="w-[80vw] xs:w-100 relative text-left">
                          Investors can sell their position early back to Finresi before the loan term expires. Finresi
                          will charge a 10% transaction fee.
                        </div>
                      }
                    >
                      <span className="text-link" onClick={(e) => onSellBack(item, e)}>
                        Retrade
                      </span>
                    </Tooltip>
                  ))}
                {item.status === InvestStatus.Pending &&
                  (declineId == item.id ? (
                    <Loading />
                  ) : (
                    <div className="text-left">
                      <span className="text-link !text-red-700" onClick={(e) => onDecline(item, e)}>
                        Decline
                      </span>
                    </div>
                  ))}
              </>
            )}
          </div>,
          `$${normalizeNumber(item.amount)}`,
          `$${monthlyProfit(item.amount, item.loan.ytm)}`,

          remainingLoanTerm(item.loan.maturityDate),

          item.startDate ? formatDate(item.startDate) : '',

          <div
            className="flex justify-between items-center gap-1 lg:w-full lg:pr-4"
            onClick={() => setSelectedInvest(item)}
          >
            <Tooltip content="View Distributions">
              <div className="flex flex-auto lg:flex-col items-center gap-1">
                <span className="text-green-400 font-bold">${normalizeNumber(item.profit || 0, 2)}</span>
                <span className="lg:hidden">/</span>
                <span className="text-gray-500">${normalizeNumber(item.pendingProfit || 0, 2)}</span>
              </div>
            </Tooltip>
          </div>,
        ])}
        classNames={['pl-4', 'px-4 lg:px-0', '', '', '', '', '', '', '', 'capitalize']}
      />

      {selectedItem && !selectedInvest && (
        <InvestDetails
          data={selectedItem}
          isMine={!isAdmin}
          onClose={(needRefresh: boolean) => {
            setSelectedItem(null)
            needRefresh && refetch()
          }}
        />
      )}

      {selectedInvest && (
        <DistributionsModal
          invest={selectedInvest}
          isAdmin={isAdmin}
          onClose={() => {
            setSelectedItem(null)
            setSelectedInvest(undefined)
          }}
        />
      )}
    </>
  )
}

export const InvestTab = (props: IProps) => {
  const { userId = undefined, userName = undefined } = props

  const [filters, setFilters] = useState<Record<string, any>>({
    duration: '-',
    orderBy: 'id',
    status: [InvestStatus.Pending, InvestStatus.Posted, InvestStatus.Settled, InvestStatus.Returned],
    orderDir: -1,
    pageNum: 0,
  })
  const [isLoading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [invests, setInvests] = useState<DBInvestment[]>([])

  useEffect(() => {
    refetch(filters)
  }, [userId])

  const refetch = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (invests.length == 0) _pageNum = 0

    filters.pageNum = _pageNum
    setFilters(filters)

    setLoading(true)
    getInvestments(
      {
        ...filters,
        // status,
        skip: _pageNum * itemCountPerPage,
        count: itemCountPerPage,
      },
      userId,
    )
      .then(({ total, data }) => {
        if (_pageNum == 0) setInvests(data)
        else {
          const newTrxes = cloneDeep(invests)
          newTrxes.push(...data)
          setInvests(newTrxes)
        }

        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  const onChangeFilter = (key: 'status' | 'orderBy' | 'orderDir' | 'pageNum', value: any, needRefetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    refetch(newFilters, needRefetch ? 0 : -1)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading && invests.length == 0} />

      <p className="font-bold text-lg mb-2">
        {userName ? userName + `'s` : 'My'} Investments ({total})
      </p>
      <div className="flex items-end gap-2 flex-wrap mb-2 justify-between">
        <ButtonGroup
          title={Object.values(InvestStatus)}
          value={filters.status}
          className="capitalize"
          multiple
          showCheck
          onChange={(v) => onChangeFilter('status', v)}
        />
      </div>

      <InfiniteScroll
        dataLength={invests.length}
        next={() => onChangeFilter('pageNum', filters.pageNum + 1, false)}
        hasMore={invests.length < total}
        loader={<div className="relative h-10">{isLoading && <LayoutLoading show />}</div>}
      >
        <InvestTable
          filters={filters}
          invests={invests}
          refetch={(filters: Record<string, any>) => refetch(filters, 0)}
          isAdmin={!!userId && !!userName}
        />
      </InfiniteScroll>
    </div>
  )
}
