import { LayoutLoading } from 'components/LayoutLoading'
import { useRef, useState } from 'react'
import { useTitle } from 'utils'

export const PrivacyPolicyPage = () => {
  useTitle('Privacy Policy')

  const iframeRef = useRef(null)
  const [isLoading, setLoading] = useState(true)
  const [height, setHeight] = useState(0)

  return (
    <div className="fullContent pt-16">
      <p className="text-4xl font-semibold mb-10 text-center">Privacy Policy</p>

      <iframe
        src="/Privacy-Policy.htm"
        onLoad={() => {
          setHeight((iframeRef.current as any).contentWindow.document.body.scrollHeight)
          setLoading(false)
        }}
        className="w-full"
        ref={iframeRef}
        height={height}
      />
      {isLoading && (
        <div className="relative w-full h-96">
          <LayoutLoading show />
        </div>
      )}
    </div>
  )
}
