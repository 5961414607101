import { Modal } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'

import { type ApproveFailedResult } from '../type'

export const FailedResultModal = ({
  failedResults,
  onClose,
}: {
  onClose: any
  failedResults: ApproveFailedResult[]
}) => {
  const renderHistory = () => {
    const header = ['No', 'Distribution ID', 'Name', 'Reason']
    const data = failedResults.map((item, index) => [index + 1, item.id, item.name, <pre>{item.message}</pre>])
    return (
      <div className="-mb-6">
        <PlainTable header={header} data={data} />
      </div>
    )
  }

  const title = `Distribution History`

  return (
    <Modal title={title} titleOkay="" onClose={onClose} isOpen>
      <div className="max-w-screen-xl">
        <div className="w-full">{renderHistory()}</div>
      </div>
    </Modal>
  )
}
