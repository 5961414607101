import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import DOMPurify from 'dompurify'
import type { IArticle } from 'pages/Article/types'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { getBlogBySlug } from 'services/apis/articles'
import { formatTime, useTitle } from 'utils'

export const BlogPage = () => {
  const { slug } = useParams()
  const [article, setArticle] = useState<IArticle>()
  useTitle('Blog')
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)

    getBlogBySlug(slug ?? '').then((article: IArticle) => {
      setArticle(article)
      useTitle(article.title)
    })
  }, [])
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div>
      <Helmet>
        <title>{article?.pageTitle && article?.pageTitle.length > 0 ? article?.pageTitle : article?.title}</title>
        <meta
          name="description"
          content={
            article?.metaDescription && article?.metaDescription.length > 0 ? article?.metaDescription : article?.title
          }
        />
        <meta name="keywords" content={article?.tags} />
        <meta
          property="og:title"
          content={article?.pageTitle && article?.pageTitle.length > 0 ? article?.pageTitle : article?.title}
        />
        <meta
          property="og:description"
          content={
            article?.metaDescription && article?.metaDescription.length > 0 ? article?.metaDescription : article?.title
          }
        />
        <meta property="og:image" content={article?.imageURL} />
      </Helmet>
      <div className="w-full bg-white overflow-x-hidden">
        <div className="bg-indigo-900 text-white pt-8 pb-16">
          <div className="fullContent">
            <p className="text-white text-xs cursor-pointer" onClick={handleBack}>
              <span className="text-xs flex items-center gap-1">
                <ArrowLeftIcon className="h-3" />
                Back to the Blog
              </span>
            </p>
          </div>

          <div className="flex flex-col content px-4 md:px-12 mt-8">
            <h1 className="text-xl md:text-4xl font-medium mb-4 text-center">{article?.title}</h1>
            <p className="mb-6 text-center">
              <span className="text-xs">{formatTime(article?.createdAt)}</span>
            </p>
          </div>
        </div>
        <div className="bg-white:after{@apply content-['_'] border-l-[100vw] border-l-transparent border-t-[120px] border-t-indigo-900 border-solid}"></div>

        <div className="flex flex-col content items-center -mt-40">
          <div className="bg-white text-black p-6 rounded-xl shadow-xl max-w-196 px-8 py-8 md:px-24 md:py-20 mb-24 md:w-196">
            <div className="flex space-x-2 mb-4">
              {article?.tags.split(',').map((tag, index1) => (
                <span
                  key={index1}
                  className="bg-green-100 text-light-green text-xs font-semibold mr-1 px-1 py-0.5 rounded"
                >
                  {tag}
                </span>
              ))}
            </div>
            <Image
              src={article?.imageURL}
              className="min-w-full mb-6 rounded-lg shadow-sm"
              alt={article?.altImage && article?.altImage.length > 0 ? article?.altImage : article?.title}
            />
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article?.description ?? '') }}
              className="ck-content"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
