export interface IArticle {
  id: number
  title: string
  description?: string
  tags: string
  image: string
  imageURL: string
  link: string
  createdAt: Date
  updatedAt: Date
  articleType: articleType
  articleSlug: string
  pageTitle: string
  metaDescription: string
  altImage: string
}

export enum articleType {
  ARTICLE = 'Article',
  BLOG = 'Blog',
}
