import { ArrowDownIcon, ClockIcon, HomeIcon, PlusIcon } from '@heroicons/react/24/outline'
import { LogoBlue } from 'assets'
import { Image } from 'components/Image'
import * as moment from 'moment-timezone'
import { InvestStatus } from 'pages/Payment'
import { ButtonGroup } from 'stories/components'
import { firstName, flagLink, normalizeNumber, thisTime } from 'utils'

import { dummyLoans } from './data'

interface BalanceStatus {
  title: string
  amount: number
}

const balanceTexts: Record<string, string> = {
  available: 'Cash Balance',
  holding: 'Pending Withdrawal',
  upcoming: 'Upcoming Deposit',
}

export const DashboardDemo = () => {
  const name = 'Adam Muflihun'
  const activeInvestCount = 12

  const balance = {
    available: 26500,
    withdrawn: 10000,
    upcoming: 25000,
    holding: 45000,
    invested: 150000,
    profits: 16500,
  }
  const estimateProfit = 45000

  const balances: BalanceStatus[] = [
    {
      title: 'Total Profits',
      amount: normalizeNumber(balance?.profits || 0, 0),
    },
    {
      title: 'Invested Cash',
      amount: normalizeNumber(balance?.invested || 0),
    },
  ]

  const overview = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const topAmount = 12

  const renderBalance = (title: string | JSX.Element, amount: string | number, isGreen: boolean = false) => {
    return (
      <div key={`balance${amount}`} className="bg-white rounded-lg px-4 py-4 md:py-2">
        <p className={`${isGreen ? 'text-light-green' : ''} mb-1 font-semibold text-lg`}>${amount}</p>
        <p className="text-gray-500 text-xs flex items-center gap-2 capitalize">{title}</p>
      </div>
    )
  }

  const totalEarned = (
    <div className="bg-gray-100 px-4 py-2 md:py-8 flex flex-col gap-4 pb-2">
      <div className="flex items-center col-span-full">
        <p className="flex-1 font-semibold">Total Earned</p>
        <p className="text-xs px-2 py-1 bg-white rounded-sm">YTD</p>
        {/* <p className="text-xs px-2 py-1">All Time</p> */}
      </div>
      {balances.map((v, index) => renderBalance(v.title, v.amount, index == 0))}
      <div className="w-full border-t col-span-full" />

      {balance &&
        ['available', 'withdrawn', 'upcoming', 'holding'].map((key) =>
          renderBalance(balanceTexts[key] || key, normalizeNumber((balance as any)[key])),
        )}
      {renderBalance('Next Scheduled Distribution Amount', normalizeNumber(Math.abs(estimateProfit), 0), false)}

      {balance && (
        <p className="text-gray-500 text-2xs col-span-full">Your earnings history will appear on Payment page.</p>
      )}
    </div>
  )

  return (
    <div className="bg-white rounded-lg dashboarddemo">
      <div className="xs:flex inline-block items-center xs:justify-between lg:px-10 px-6 py-4">
        <div className="flex items-center gap-x-4">
          <LogoBlue />
          {['My Dashboard', 'Opportunities', 'How it works', 'FAQ'].map((v) => (
            <p className="text-xs font-medium" key={v}>
              {v}
            </p>
          ))}
        </div>
        <p className="border px-3 py-2 rounded-full text-xs hidden xs:block">{name}</p>
      </div>

      <div className="bg-gray-100/50 lg:px-10 px-6 py-4">
        <div className="grid grid-cols-4 gap-x-10">
          <div className="col-span-3 py-2">
            <p className="font-title text-3xl font-bold mb-4">Welcome back, {firstName(name)}</p>
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 border rounded-lg overflow-hidden divide-y">
                <div className="flex gap-4 items-center px-4 py-2 cursor-pointer hover:bg-gray-100 bg-white">
                  <HomeIcon className="text-indigo-500 w-10 h-10" />
                  <div className="flex lg:block justify-between items-baseline flex-1">
                    <p className="text-2xl font-semibold">{activeInvestCount}</p>
                    <p className="text-xs text-gray-500 text-left">Active Properties</p>
                  </div>
                </div>

                <div className="xs:flex flex items-center bg-gray-100">
                  <div className="flex items-center px-4 py-2 gap-4 flex-1 hover:bg-white cursor-pointer">
                    <img src={flagLink('US')} className="w-8 h-8 mx-1 rounded-full object-cover" />
                    <div>
                      <div className="flex gap-2">
                        <p className={`font-bold text-base`}>$176,500</p>
                        <span className="text-gray-500 text-base">USD</span>
                      </div>
                      <p className="text-xs text-gray-500 text-left">Toatal Balance</p>
                    </div>
                  </div>

                  <div className="flex-1 flex justify-between items-center px-4 py-2">
                    <div className="text-center flex flex-col justify-center items-center">
                      <div className="bg-light-green p-3 text-white rounded-full mb-1 cursor-pointer hover:bg-green-600">
                        <PlusIcon className="w-4 h-4" />
                      </div>
                      <p className="text-2xs font-semibold text-gray-700">Add</p>
                    </div>

                    <div className="text-center flex flex-col justify-center items-center">
                      <div className="bg-white p-3 text-gray-700 rounded-full mb-1 cursor-pointer hover:bg-indigo-500 hover:text-white">
                        <ArrowDownIcon className="w-4 h-4" />
                      </div>
                      <p className="text-2xs font-semibold text-gray-700">Withdraw</p>
                    </div>

                    <div className="text-center flex flex-col justify-center items-center">
                      <div className="bg-white p-3 text-gray-700 rounded-full mb-1 cursor-pointer hover:bg-indigo-500 hover:text-white">
                        <ClockIcon className="w-4 h-4" />
                      </div>
                      <p className="text-2xs font-semibold text-gray-700">History</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 border rounded-lg flex flex-col min-h-[inherit] relative">
                <div className="flex items-center justify-between text-xs mb-2">
                  <p className="flex-1 text-gray-500 text-xs">Earning Overview</p>

                  <div className="flex">
                    <p className="px-2 py-1 hover:cursor-pointer">{thisTime().year() - 1}</p>
                    <p className="bg-black rounded-md text-white px-2 py-1 hover:cursor-pointer">{thisTime().year()}</p>
                  </div>
                </div>

                <div className="flex gap-0 items-end flex-1">
                  {moment.monthsShort().map((text, index) => {
                    const v = overview[index]
                    return (
                      <div key={text} className="flex-1 h-full flex flex-col justify-end w-1/12 px-[0.5px]">
                        {!!v && !!topAmount && (
                          <div
                            className={`${v > topAmount / 2 ? 'bg-indigo-500' : 'bg-gray-200'} rounded-t-md mb-2`}
                            style={{ height: `${(v / topAmount) * 75}%` }}
                          />
                        )}
                        <div className="text-3xs text-gray-600 text-center">{index + 1}</div>
                      </div>
                    )
                  })}
                </div>
              </div>

              {/* <div className="col-span-full lg:hidden block">{totalEarned}</div> */}

              <div className="col-span-full py-4 relative">
                <div className="flex justify-between mb-4">
                  <p className="font-bold text-lg">Your Active Investment (3)</p>
                </div>
                <div className="grid grid-cols-3 gap-4 w-full mb-4">
                  {dummyLoans
                    .filter((v, index) => index < 3)
                    .map((invest, index) => (
                      <div className="bg-white rounded-lg overflow-hidden border" key={index}>
                        <div className="relative">
                          <Image src={invest.image} className="w-full aspect-video object-cover" />
                        </div>

                        <div className="bg-white p-2 border-l border-r border-gray-300">
                          <div className="flex gap-4 items-center mb-2">
                            <div className="flex-1">
                              <p className="text-xs">{invest.progress}% Funded</p>
                              <div className="w-full bg-gray-200 rounded-full">
                                <div
                                  className="bg-light-green h-1 rounded-full"
                                  style={{ width: `${invest.progress}%` }}
                                />
                              </div>
                            </div>
                            {!invest.closed ? (
                              <span className="bg-light-green text-2xs text-white font-semibold px-1.5 py-1 rounded-md capitalize">
                                Available
                              </span>
                            ) : (
                              <span className="bg-yellow-300 text-2xs text-black font-semibold px-1.5 py-1 rounded-md capitalize">
                                Closed
                              </span>
                            )}
                          </div>

                          <p className="font-bold text-xs">{invest.loan}</p>
                          <p className="text-2xs text-gray-400">{invest.title}</p>
                        </div>
                        <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0">
                          <div className="flex divide-x">
                            {[
                              [`${invest.ytm}%`, 'APY'],
                              [`${normalizeNumber(invest.ltv, 0)}%`, 'LTV'],
                              [invest.term, 'TERM'],
                            ].map((v) => (
                              <div key={v[1]} className="flex-1 text-center py-2">
                                <p className="font-bold text-xs">{v[0]}</p>
                                <p className="text-2xs text-gray-400">{v[1]}</p>
                              </div>
                            ))}
                          </div>

                          <div className={`flex gap-4 items-center p-2 pt-0`}>
                            <p className="bg-indigo-500 rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green">
                              View Details
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="block">{totalEarned}</div>

          <div className="col-span-full">
            <div className="relative">
              <p className="font-bold text-lg mb-2">My Investments (3)</p>
              <div className="flex items-end gap-2 flex-wrap mb-2 justify-between">
                <ButtonGroup
                  title={Object.values(InvestStatus)}
                  value={[InvestStatus.Pending, InvestStatus.Posted, InvestStatus.Settled]}
                  className="capitalize"
                  multiple
                  showCheck
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
