import {
  API_SELF_DOCUMENTS,
  API_SELF_IDENTITY_VERIFICATION,
  API_SELF_IDENTITY_VERIFICATION_DETAILS,
  API_SELF_PLAID_ACCOUNTS,
  API_USER_CHECK_PASSPORT,
  API_USER_CONFIRM_SELF_EMAIL,
  API_USER_CONFIRM_USER_EMAIL,
  API_USER_FORGET_PASSWORD,
  API_USER_INVESTREADY,
  API_USER_INVESTREADY_BY_ID,
  API_USER_INVESTREADY_EXPIRES_ON,
  API_USER_NOTIFICATIONS,
  API_USER_PASSWORD,
  API_USER_PROFILE,
  API_USER_RESET_PASSWORD,
  API_USER_SIGN_IN,
  API_USER_SIGN_SUB_AGREEMENT,
  API_USER_SIGN_SUB_AGREEMENT_BY_TYPE,
  API_USER_SIGN_UP_FINISH,
  API_USER_SIGN_UP_WITH_PHONE,
  API_USER_TAX_CERT,
  API_USER_VALIDATE_PHONE,
} from 'config'
import type { TransactionMethod } from 'pages/Payment'
import type { InvestReadyCert } from 'pages/Profile'
import type { PlaidAccount, PlaidInstitution } from 'react-plaid-link'
import Api from 'services/api'

import { downloadFile } from './documents'

export function validatePhone(data: Record<string, any>) {
  return Api.post(API_USER_VALIDATE_PHONE, data)
}

export function signUpWithPhoneCode(data: Record<string, any>) {
  return Api.post(API_USER_SIGN_UP_WITH_PHONE, data)
}

export function finalUserSignup(data: Record<string, any>) {
  return Api.post(API_USER_SIGN_UP_FINISH, data)
}

export function signInRequest(data: Record<string, any>) {
  return Api.post(API_USER_SIGN_IN, data)
}

export const sendForgetPasswordEmail = (email: string) => {
  return Api.post(API_USER_FORGET_PASSWORD, { email })
}

export const checkPassport = (selector: string, token: string) => {
  return Api.get(API_USER_CHECK_PASSPORT, {}, { selector, token })
}

export const sendConfirmEmailToSelf = () => {
  return Api.get(API_USER_CONFIRM_SELF_EMAIL, {})
}

export const resetPassword = (selector: string, token: string, password: string) => {
  return Api.post(API_USER_RESET_PASSWORD, { password }, { selector, token })
}

export const confirmUserEmail = (selector: string, token: string) => {
  return Api.post(API_USER_CONFIRM_USER_EMAIL, {}, { selector, token })
}

export const updatePassword = (data: Record<string, any>) => {
  return Api.put(API_USER_PASSWORD, data)
}

export const getProfile = () => {
  return Api.get(API_USER_PROFILE)
}

export const updateNotifications = (data: Record<string, any>) => {
  return Api.put(API_USER_NOTIFICATIONS, data)
}

export const updateProfile = (data: Record<string, any>) => {
  return Api.put(API_USER_PROFILE, data)
}

export const signSubAgreement = (methods: TransactionMethod[]) => {
  return Api.post(API_USER_SIGN_SUB_AGREEMENT, { methods })
}

export const updateSubAgreement = (envelopeId: string) => {
  return Api.put(API_USER_SIGN_SUB_AGREEMENT, { envelopeId })
}

export const openSubAgreement = () => {
  return Api.get(API_USER_SIGN_SUB_AGREEMENT_BY_TYPE, {}, { type: 'url' })
}

export const downloadBlankSubAgreement = async (method: TransactionMethod) => {
  const res: Blob = await Api.get(
    API_USER_SIGN_SUB_AGREEMENT_BY_TYPE,
    { method },
    { type: 'doc' },
    {
      responseType: 'blob',
    },
  )
  downloadFile(`Finresi Subscription Agreement.pdf`, res)
}

export const getInvestReady = (): Promise<InvestReadyCert[]> => {
  return Api.get(API_USER_INVESTREADY)
}

export const getInvestReadyExpiresOn = (): Promise<{ expiresOn: string }> => {
  return Api.get(API_USER_INVESTREADY_EXPIRES_ON)
}

export const createInvestReady = (formId: number, data: Record<string, any>, reason: string) => {
  return Api.post(API_USER_INVESTREADY_BY_ID, { data, reason }, { id: formId })
}

export const updateInvestReady = (id: number, data: Record<string, any>, reason: string) => {
  return Api.put(API_USER_INVESTREADY_BY_ID, { data, reason }, { id })
}

// export const refreshInvestReady = () => {
//   return Api.put(API_USER_INVESTREADY)
// }

export const getTaxCerts = () => {
  return Api.get(API_USER_TAX_CERT)
}

export const signTaxCert = () => {
  return Api.post(API_USER_TAX_CERT)
}

export const confirmTaxCert = (envelopeId: string) => {
  return Api.put(API_USER_TAX_CERT, { envelopeId })
}

export const getPlaidIdentityVerification = () => {
  return Api.get(API_SELF_IDENTITY_VERIFICATION)
}

export const getPlaidIdentityVerificationDetails = () => {
  return Api.get(API_SELF_IDENTITY_VERIFICATION_DETAILS)
}

export const updatePlaidIdentityVerification = (sessionId: string, publicToken: string | null) => {
  return Api.post(API_SELF_IDENTITY_VERIFICATION, { sessionId, publicToken })
}

export const getPlaidAccounts = () => {
  return Api.get(API_SELF_PLAID_ACCOUNTS)
}

export const addPlaidAccounts = (institution: PlaidInstitution, accounts: PlaidAccount[], publicToken: string) => {
  return Api.post(API_SELF_PLAID_ACCOUNTS, { institution, accounts, publicToken })
}

export const deletePlaidAccount = (accountId: string) => {
  return Api.delete(API_SELF_PLAID_ACCOUNTS, { accountId })
}

export const getDocuments = (filters: Record<string, any>) => {
  return Api.get(API_SELF_DOCUMENTS, filters)
}
