import type { BaseFile } from 'config'
import type { LoanStatus } from 'pages/Marketplace'
import type { DbBalance } from 'pages/Payment'
import type { User } from 'pages/User/type'
import type { TransferIntentCreateMode } from 'plaid'
import { renderTable } from 'utils'

export interface Statistics {
  user: {
    count: number
  }
  loan: Record<LoanStatus, number>
  invest: {
    amount: number
    total: number
    active: number
  }
  profit: {
    total: number
  }
  payment: {
    deposit: number
    withdraw: number
  }
}

export interface PaymentStatistics {
  month: number
  amount: number
  direction: TransferIntentCreateMode
}

export interface Balance extends DbBalance {
  name: string
  email: string
}

/**
 * Task
 */
export enum TaskType {
  User = 'user',
  Wire = 'wire',
  Plaid = 'plaid',
  Kyc = 'kyc',
  Custom = 'custom',
  Invest = 'invest',
  Distribution = 'distribution',
  InvestReady = 'investready',
  TaxCert = 'taxcert',
}

export const TaskTypeText = {
  user: 'User',
  wire: 'Wire',
  plaid: 'Plaid',
  kyc: 'Kyc',
  custom: 'Custom',
  invest: 'Invest',
  distribution: 'Distribution',
  investready: 'InvestReady',
}

export enum TaskStatus {
  ACTIVE = 'active',
  COMPLETE = 'complete',
}

export interface DbTask {
  id: number
  assignedTo: string[]
  type: TaskType
  refId: number
  title: string
  description: string
  files: BaseFile[]
  status: TaskStatus
  dueDate: Date
  // creator: User
  completor: User
  completedDate: string
  deleted: boolean
  createdAt: Date
  updatedAt: Date

  creator: string
  commentHistory: TaskCommentHistory[]
}

export interface TaskCommentHistory {
  status: TaskStatus
  comment: string
  commentedBy: string
  createdAt: number
}

export const renderDescription = (description: string) => {
  if (!description.startsWith('[')) return description.replace(/\n/g, '<br />')

  try {
    const lines = JSON.parse(description)
    return renderTable(lines)
  } catch (e) {
    return description
  }
}
