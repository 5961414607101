import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import type { BaseFile, CustomInput, InputFileTable } from 'config'
import { cloneDeep } from 'lodash'
import { type Loan, LoanDocument, LoanOrigin } from 'pages/Marketplace'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLoan, updateLoan } from 'services/apis'
import { Button } from 'stories/components'
import {
  formatDateYMD,
  InputConvert,
  loanIdentifier,
  openAuditLog,
  removeComma,
  RenderInput,
  toS3Link,
  useTitle,
  validateInputs,
} from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from './constants'
import { NextresDocument, NextresDocumentsDialog } from './NextresDocumentsDialog'

export const LoanDetailsPage = () => {
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [loan, setLoan] = useState<Loan | null>(null)
  const [errors, setErrors] = useState<string[]>([])
  const [showMoreDocuments, setShowMoreDocuments] = useState(false)

  const { id } = useParams() as any
  const isNewLoan = id == 'new'

  useEffect(() => {
    if (isNewLoan) {
      useTitle('Create New Loan')
      return
    }

    setLoading(true)
    getLoan(id)
      .then(({ loan }: { loan: Loan }) => {
        const newInputs = cloneDeep(inputs)
        Object.keys(loan).forEach((key) => {
          if (newInputs[key]) {
            if (newInputs[key].type == 'date') newInputs[key].value = formatDateYMD((loan as any)[key])
            else newInputs[key].value = (loan as any)[key]
          }
        })
        setLoan(loan)
        ;(newInputs.images as InputFileTable).filePath = `loan/${id}`
        ;(newInputs.imagePreview as CustomInput).render = renderImages
        newInputs.imagePreview.required = false
        ;(newInputs.documents as InputFileTable).filePath = `loan/${id}`
        ;(newInputs.documents as InputFileTable).moreControls = renderSelectMoreDocs()

        newInputs.availableInvestmentAmount.value =
          removeComma(newInputs.loanAmount.value) - removeComma(newInputs.yldInvestment.value)

        setInputs(newInputs)
        useTitle(`Loan Detail ${loanIdentifier(loan)}`)
      })
      .finally(() => setLoading(false))
  }, [])

  const onOpenLog = (key: string) => {
    setLoanNumber()
    const options = {
      table: 'Loan',
      field: key,
      keys: {
        field: key,
        loanNumber: id,
      },
    }
    openAuditLog(options)
  }

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    if (['interestRate', 'lenderSpread', 'yieldeasySpread'].includes(key)) {
      newState.ytm.value =
        Number(newState.interestRate.value) -
        Number(newState.lenderSpread.value) -
        Number(newState.yieldeasySpread.value)
      newState.ytm.error = ''
    }
    if (['loanAmount', 'yldInvestment'].includes(key)) {
      newState.availableInvestmentAmount.value =
        removeComma(newState.loanAmount.value) - removeComma(newState.yldInvestment.value)
      newState.availableInvestmentAmount.error = ''
    }
    setInputs(newState)
  }

  const onSelectMoreDocs = () => {
    setShowMoreDocuments(true)
  }

  const onImportMoreDocuments = (newDocs: NextresDocument[]) => {
    let newState = cloneDeep(inputs)
    const documents = newState.documents as InputFileTable
    documents.error = ''
    const id = Date.now()

    newDocs.forEach((doc, index) => {
      ;(documents.value as LoanDocument[]).push({
        id: id + index,
        name: doc.name,
        fileKey: doc.fileKey,
        category: '',
        originalKey: doc.fileKey,
        createdAt: Date.now(),
      })
    })
    setInputs(newState)
    setShowMoreDocuments(false)
  }

  const onSubmit = () => {
    const { hasError, data, newInputStates } = validateInputs(inputs)

    if (hasError) {
      const validationErrors: string[] = []

      Object.keys(newInputStates).forEach((key) => {
        const value = newInputStates[key]
        let errMsg = ''

        if (value?.error) {
          if (['documents', 'images'].includes(key)) errMsg = `${value.title} are required!`
          else errMsg = `${value.title} is required!`

          validationErrors.push(errMsg)
        }
      })

      setErrors(validationErrors)
      setInputs(newInputStates)
      return
    } else setErrors([])

    setLoading(true)

    delete data.availableInvestmentAmount

    updateLoan(id, data)
      .then(() => {
        const text = !loan ? 'New Loan has been created.' : `Loan ${loanIdentifier(loan)} has been updated.`
        toast(text, { type: 'success' })
        navigate('/pipeline')
      })
      .finally(() => setLoading(false))
  }

  const renderImages = () => {
    return (
      <div className="mt-4">
        <p className="text-sm mb-2">Preview</p>
        <div className="grid grid-cols-6 gap-2">
          {loan &&
            inputs.images.value.map((image: BaseFile) => (
              <div key={image.id} className="overflow-hidden relative rounded-md border border-gray-200">
                <Image
                  src={toS3Link(image)}
                  className="absolute w-full h-full aspect-video max-h-32 rounded-lg mb-2 cursor-pointer object-cover"
                />
                <div className="absolute w-full h-full backdrop-blur-md bg-white/50" />
                <Image
                  src={toS3Link(image)}
                  className="relative w-full h-full aspect-video max-h-32 rounded-lg mb-2 cursor-pointer object-contain"
                />
              </div>
            ))}
        </div>
      </div>
    )
  }

  const renderSelectMoreDocs = () => {
    return (
      <label
        className="text-gray-700 p-1 hover:underline hover:text-indigo-500 cursor-pointer rounded"
        onClick={onSelectMoreDocs}
      >
        More Documents from Nextres
      </label>
    )
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <Button link onClick={() => navigate('/pipeline')}>
        <p className="flex items-center gap-2">
          <ArrowLeftIcon className="w-6 h-6" />
          Back to Pipeline
        </p>
      </Button>

      <p className="text-4xl font-semibold mb-10">{!loan ? 'Create new Loan' : `Loan ${loanIdentifier(loan)}`}</p>

      <LayoutLoading show={isLoading} />

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 grid-cols-1 mb-6">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          if (key == 'imagePreview') (input as any).render = renderImages
          input.history = !isNewLoan

          return (
            <div className={`input col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} showHistory={onOpenLog} />
            </div>
          )
        })}
      </div>

      {errors.length > 0 && (
        <div className="py-2 px-4 mb-4 border border-red-500 rounded bg-red-50">
          <p className="font-semibold text-red-500">Following fields are required to save this loan.</p>

          {errors.map((err, index) => (
            <div key={index} className="text-red-500 pt-1">
              - {err}
            </div>
          ))}
        </div>
      )}

      <div className="flex gap-2 justify-center">
        <Button onClick={onSubmit}>Submit</Button>
        <Button onClick={() => navigate('/pipeline')} color="white">
          Cancel
        </Button>
      </div>

      {loan && loan.origin == LoanOrigin.COMMERCIAL && showMoreDocuments && (
        <NextresDocumentsDialog
          id={loan.id}
          documents={inputs.documents.value}
          onClose={() => setShowMoreDocuments(false)}
          onSubmit={onImportMoreDocuments}
        />
      )}
    </div>
  )
}

export * from './NextresDocumentsDialog'
