import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { isManager } from 'config'
import { TransferStatus } from 'plaid'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import type { RootState } from 'reducers'
import { normalizeNumber } from 'utils'

import type { Loan } from './types'

export const FundedBy = ({
  loan,
  fromCard = false,
}: // small = false,
{
  loan: Loan
  fromCard?: boolean
  small?: boolean
}) => {
  const { profile } = useSelector((state: RootState) => state.auth)
  const _isManager = profile && isManager(profile.accountType)

  const text_class = 'text-right' // _isManager ? 'text-right' : 'text-left'

  const progress = <p className={`text-2xs text-gray-500 flex-1 ${text_class}`}>Remaining Investment</p>

  const availableElements = (
    <div className="text-right">
      {progress}
      <p className={`text-gray-500 ${text_class} text-[14.5px] ${fromCard ? '' : 'mt-1'}`}>
        <span className={`pl-1 font-bold text-light-green`}>
          $ {normalizeNumber(loan.loanAmount - loan.yldInvestment - loan.funds - loan.constructionBalance)}
        </span>
      </p>
    </div>
  )
  const progressBar = (
    <div className="w-full bg-gray-300 rounded-full overflow-hidden mb-1">
      <div className={`bg-light-green h-1.5 rounded-full`} style={{ width: `${loan.progress}%` }} />
    </div>
  )

  const topElement = (
    <div className="flex justify-between items-center">
      <p className="text-left text-lg font-semibold">{loan.progress}% Funded</p>
      {availableElements}
    </div>
  )

  if (!_isManager)
    return (
      <>
        {topElement}
        {progressBar}
      </>
    )

  const innerElement = (
    <p className="text-xs hover:underline">
      <span className="text-gray-500">Funded by {loan.investors} investors</span>
    </p>
  )
  const statusList = [TransferStatus.Pending, TransferStatus.Posted, TransferStatus.Settled]

  return (
    <>
      {topElement}
      {progressBar}
      <Link
        to={`/manageSignStatistics?query=__${loan.id}&status=${statusList.join(',')}`}
        className="flex gap-1 items-center"
      >
        {innerElement}
        <ArrowRightIcon className="w-3 h-3 text-gray-500" />
      </Link>
    </>
  )
}
