import { ArrowPathIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { AccountStatus } from 'config'
import { CompleteVerificationProgress } from 'pages/Marketplace'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { getBalances, getPlaidAccounts, getPlaidIdentityVerification } from 'services/apis'
import { Button } from 'stories/components'
import { isInvestableProfile, normalizeNumber } from 'utils'

import { AccountsTab } from './Accounts'
import { type DbBalance, type DbPlaidAccount, PaymentModalType } from './constants'
import { DepositModal } from './Deposit/Deposit'
import { HistoriesTab } from './Histories'
import { MyEarningsTab } from './MyEarnings'
import { WithdrawModal } from './Withdraw/Withdraw'

export const PaymentPage = ({ tabIndex: _tabIndex = 0 }: { tabIndex?: number }) => {
  const navigate = useNavigate()
  const profile = useSelector((state: RootState) => state.auth.profile)
  const [action, setAction] = useState('')
  const [tabIndex, setTabIndex] = useState(-1)
  const [accounts, setAccounts] = useState<DbPlaidAccount[]>([])
  const [balance, setBalance] = useState<DbBalance | null>(null)
  const [defaultTrxId, setDefaultTrxId] = useState(0)
  const [isUpdatedOnce, setUpdatedOnce] = useState(false)
  const [isVerificationProgress, showVerificationProgress] = useState('')
  const [paymentModalType, setPaymentModalType] = useState<PaymentModalType | null>(null)

  const location = useLocation()

  useEffect(() => {
    if (!location.hash) {
      setDefaultTrxId(0)
      return
    }
    const trxId = Number(location.hash.replace('#', ''))
    setDefaultTrxId(trxId)
  }, [location.hash])

  useEffect(() => {
    if (profile.status < AccountStatus.KycPassed) {
      setTabIndex(0)
      // navigate('/kyc')
      return
    }

    setAction('accounts')
    getPlaidIdentityVerification().then(({ status }) => {
      if (status != 'success') {
        toast('Please verify identity first.', { type: 'warning' })
        navigate('/kyc')
        return
      }
      if (_tabIndex == 0) setTabIndex(_tabIndex)
      else updateAccounts().then(() => setTabIndex(_tabIndex))
      updateBalances(false)
    })
  }, [_tabIndex])

  const balanceArr = useMemo(() => {
    return [
      {
        title: 'Available',
        value: balance ? normalizeNumber(balance.available, 2) : '---',
      },
      {
        title: 'Lifetime Deposited',
        value: balance ? normalizeNumber(balance.deposited, 2) : '---',
      },
      {
        title: (
          <>
            Upcoming
            <span className="text-gray-500 text-xs">(Deposit)</span>
            <InformationCircleIcon className="w-4 h-4" />
          </>
        ),
        value: balance ? normalizeNumber(balance.upcoming, 2) : '---',
      },
      {
        title: (
          <>
            Pending
            <span className="text-gray-500 text-xs">(Withdraw)</span>
          </>
        ),
        value: balance ? normalizeNumber(balance.holding, 2) : '---',
      },
      {
        title: 'Invested',
        value: balance ? normalizeNumber(balance.invested) : '---',
      },
      {
        title: 'Distributions',
        value: balance ? normalizeNumber(balance.profits, 2) : '---',
      },
    ]
  }, [balance])

  const isLargeBalance = useMemo(() => {
    if (!balance) return false
    const maxValue = Math.max(
      ...Object.keys(balance)
        .map((key) => Number((balance as any)[key]))
        .filter((v) => !isNaN(v)),
    )
    return maxValue > 10000000
  }, [balance])

  const updateAccounts = () => {
    return getPlaidAccounts().then((data) => setAccounts(data))
  }

  const updateBalances = (needUpdate = false) => {
    setAction('balance')
    getBalances(needUpdate)
      .then((data) => {
        data && setBalance(data)
        if (needUpdate) setUpdatedOnce(true)
      })
      .finally(() => setAction(''))
  }

  const onTransaction = async (url: string, type: PaymentModalType | null = null) => {
    const isInvestable = await isInvestableProfile(type === PaymentModalType.Withdraw)
    if (!isInvestable) {
      showVerificationProgress(url)
      return
    }

    if (type == null) {
      navigate(url)
      return
    }

    setPaymentModalType(type)
  }

  return (
    <div className="w-full relative py-4 md:py-10 min-h-[100vh]">
      {/* <div className="bg-indigo-800 w-full absolute top-0 xs:h-196 h-260 md:h-144 lg:h-96" /> */}
      <div className="fullContent relative">
        <div className="flex-1 py-4 lg:p-0 mb-2 lg:mb-10">
          <div className="flex gap-4 items-center mb-4">
            <p className="text-xl">Your Earnings Summary</p>
            <div onClick={() => updateBalances(true)} className={`${isUpdatedOnce && 'hidden'}`}>
              <ArrowPathIcon className="w-6 h-6 cursor-pointer" />
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 lg:mb-4 relative">
            {balanceArr.map(({ title, value }, index) => (
              <div className="flex-[1] mb-4 lg:mb-0" key={index}>
                <p className="text-lg lg:text-sm flex gap-2 items-center">{title}</p>
                {action == 'balance' ? (
                  <div className="mb-2.5">
                    <Loading />
                  </div>
                ) : (
                  <p
                    className={`${index == 0 && 'text-green-400'} ${
                      isLargeBalance ? 'text-2xl' : 'text-3xl'
                    } font-semibold lg:font-medium`}
                  >
                    $ {value}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between items-start lg:items-center mb-2 flex-col-reverse lg:flex-row">
          <div className="grid w-full grid-cols-1 xs:flex xs:flex-row sm:gap-x-6 xs:gap-x-2 gap-y-4 text-2xl font-semibold flex-[2] mb-4">
            {['Accounts', 'Transactions', 'My Earnings'].map((title, index) => (
              <p
                className={`${tabIndex == index ? 'text-black' : 'text-gray-500'} cursor-pointer hover:text-gray-800`}
                onClick={() => {
                  setDefaultTrxId(0)
                  setTabIndex(index)
                  setUpdatedOnce(false)
                }}
                key={title}
              >
                {title}
              </p>
            ))}
          </div>

          <div className="grid grid-cols-1 xs:grid-cols-2 gap-4 lg:gap-2 flex-1 w-full mb-6 lg:mb-0">
            <Button
              color="green"
              size="md"
              className="px-2 flex-1"
              onClick={() => {
                setUpdatedOnce(false)
                onTransaction('/deposit', PaymentModalType.Deposit)
              }}
            >
              + Deposit Funds
            </Button>
            <Button
              color="white"
              className="bg-white px-2 flex-1"
              onClick={() => {
                setUpdatedOnce(false)
                onTransaction('/withdraw', PaymentModalType.Withdraw)
              }}
            >
              - Withdraw Funds
            </Button>
          </div>
        </div>

        {tabIndex == 0 && (
          <AccountsTab
            updateAccounts={updateAccounts}
            accounts={accounts}
            showVerificationProgress={() => showVerificationProgress('/account')}
          />
        )}
        {tabIndex == 1 && <HistoriesTab accounts={accounts} defaultTrxId={defaultTrxId} />}
        {tabIndex == 2 && <MyEarningsTab />}

        {!!isVerificationProgress && (
          <CompleteVerificationProgress
            investReady
            from={isVerificationProgress}
            onClose={() => showVerificationProgress('')}
          />
        )}
        {paymentModalType == PaymentModalType.Deposit && <DepositModal onClose={() => setPaymentModalType(null)} />}
        {paymentModalType == PaymentModalType.Withdraw && <WithdrawModal onClose={() => setPaymentModalType(null)} />}
      </div>
    </div>
  )
}
