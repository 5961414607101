import { XMarkIcon } from '@heroicons/react/24/outline'
import { BankAccount } from 'components/BankAccount'
import { LayoutLoading } from 'components/LayoutLoading'
import {
  TransactionColorMap,
  TransactionEventIcon,
  TransactionEventTexts,
  TransactionStatus,
} from 'components/TransactionStatus'
import { isAppDev, isTopManager } from 'config'
import { TransferIntentCreateMode, TransferStatus } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { addTransactionEvent, getTransactionById } from 'services/apis'
import { Button, Modal } from 'stories/components'
import { confirm, formatTime, normalizeNumber, prompt } from 'utils'

import { DbTransaction, TransactionMethod, WireTransactionData } from '../constants'

export const TransactionDetails = ({ id, onClose }: { id: number; onClose: Function }) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<DbTransaction | null>(null)
  const [isUpdated, setUpdated] = useState(false)

  const auth = useSelector((state: RootState) => state.auth)
  const { accountType } = auth.profile
  const _isManager = isTopManager(accountType)

  useEffect(() => {
    getTransactionById(id)
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }, [])

  const isAbleAction = useMemo(() => data && data.method == TransactionMethod.Wire && _isManager, [data])
  const isCancellable = useMemo(
    () => data && data.method == TransactionMethod.Plaid && TransferStatus.Pending == data.status && _isManager,
    [data],
  )

  const renderTransferId = (transferId: string) => {
    const link = `https://dashboard.plaid.com/transfer/${transferId}${isAppDev ? '?environment=sandbox' : ''}`

    return (
      <a className="text-link cursor-pointer normal-case" href={link} target="_blank">
        {transferId}
      </a>
    )
  }

  const renderSweepId = (sweepId: string) => {
    const link = `https://dashboard.plaid.com/transfer/sweeps/${sweepId}${isAppDev ? '?environment=sandbox' : ''}`

    return (
      <a className="text-link cursor-pointer" href={link} target="_blank">
        {sweepId}
      </a>
    )
  }

  const renderData = useMemo(() => {
    if (!data) return {}
    const strAmount = normalizeNumber(data.amount, 2)
    const strDirection = data.direction == TransferIntentCreateMode.Payment ? 'Deposit' : 'Withdraw'
    const bankAccount = <BankAccount {...data.bank} />

    let result: Record<string, any> = {
      Direction: strDirection,
      Method: data.method,
      'Bank Account': bankAccount,
    }
    if (data.direction == TransferIntentCreateMode.Disbursement && data.method == TransactionMethod.Wire && data.data)
      result = {
        ...result,
        'Routing Number': (data.data as WireTransactionData).routingNumber,
        'Account Number': (data.data as WireTransactionData).accountNumber,
        'Sub Type': (data.data as WireTransactionData).subType,
      }
    result = {
      ...result,
      [`Amount to ${strDirection}`]: `$ ${strAmount}`,
      Status: <TransactionStatus status={data.status} />,
      Date: formatTime(data.createdAt),
      'Transfer Id': data.method == TransactionMethod.Plaid ? renderTransferId(data.transferId) : data.transferId,
    }

    return result
  }, [data])

  const isDisabled = (curStatus: TransferStatus, status: TransferStatus) => {
    if (curStatus == status || (curStatus == TransferStatus.Pending && status == TransferStatus.Settled)) return true
    return false
  }

  const onEvent = async (status: TransferStatus) => {
    if (!data || status == data?.status) return

    let text
    if (data.method == TransactionMethod.Wire)
      text = (
        <div className="mb-2">
          Are you sure to update status to <TransactionStatus status={status} />?
        </div>
      )
    else text = <div className="mb-2">Are you sure to cancel this transaction?</div>

    const result = await confirm(text)
    if (!result) return

    let reason = null
    if ([TransferStatus.Failed, TransferStatus.Returned, TransferStatus.Cancelled].includes(status)) {
      reason = await prompt('Input the reason.')
      if (!reason) return
    }

    setUpdated(true)
    setLoading(true)
    addTransactionEvent(id, { status, reason, method: data!.method })
      .then(() => toast('Transaction status has been updated', { type: 'info' }))
      .then(() => getTransactionById(id))
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  return (
    <Modal isOpen title={`Transaction #${id}`} titleOkay="" onClose={() => onClose(isUpdated)}>
      <div className={`text-gray-600 text-md md:w-128`}>
        <LayoutLoading show={isLoading} />
        {Object.keys(renderData).map((title) => (
          <div className="mb-2 flex gap-2 justify-between items-center" key={title}>
            <p className="text-desc">{title}</p>
            <div className="text-sm capitalize">{renderData[title]}</div>
          </div>
        ))}

        {data && isAbleAction && (
          <>
            <div className="w-full border-b mb-2" />

            <p>Actions</p>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2 items-center">
              {[
                TransferStatus.Pending,
                TransferStatus.Posted,
                TransferStatus.Settled,
                TransferStatus.Failed,
                TransferStatus.Returned,
              ].map((status) => (
                <Button link onClick={() => onEvent(status)} disabled={isDisabled(data.status, status)} key={status}>
                  <div
                    className={`flex gap-2 items-center capitalize py-1 justify-center ${
                      status == data?.status ? 'bg-gray-100' : ''
                    } rounded-md`}
                  >
                    <span className={`${TransactionColorMap[status]} w-2 h-2 rounded-full`} /> {status}
                  </div>
                </Button>
              ))}
            </div>
          </>
        )}

        {isCancellable && (
          <>
            <div className="w-full border-b mb-2" />

            <p>Actions</p>
            <Button link onClick={() => onEvent(TransferStatus.Cancelled)}>
              <div className={`flex gap-2 items-center capitalize py-1 justify-center rounded-md`}>
                <XMarkIcon className="w-4 h-4 text-red-500" /> Cancel Transaction
              </div>
            </Button>
          </>
        )}

        <div className="w-full border-b mb-2" />
        <p className="mb-2">Events</p>
        {data &&
          data.events.map((event, index) => (
            <div className="flex gap-2 mb-2 w-full" key={`${event.id}-${index}`}>
              <div className="flex flex-col mt-1">
                <div className="">{<TransactionEventIcon event={event.event_type} />}</div>
                <div className="border-l ml-2 flex-1" />
              </div>
              <div className="text-desc w-full">
                <div className="text-sm mb-1 flex flex-wrap gap-2 items-center justify-between">
                  <p className="text-base font-medium uppercase text-gray-600">{event.event_type}</p>
                  {event.actor}
                </div>
                {event.failure_reason ? (
                  <p className="mb-2">
                    {event.failure_reason.description}
                    {event.failure_reason.ach_return_code && ` [${event.failure_reason.ach_return_code}]`}
                  </p>
                ) : (
                  TransactionEventTexts[event.event_type]
                )}
                {event.sweep_id && <p className="text-desc">Sweep: {renderSweepId(event.sweep_id)}</p>}
                <p>{formatTime(event.timestamp)}</p>
              </div>
            </div>
          ))}

        {data && data.comment && (
          <p className="my-2 bg-yellow-50 text-yellow-600 rounded-md p-2 text-sm border border-yellow-200">
            Comment: <span className="font-semibold">{data.comment}</span>
          </p>
        )}
      </div>
    </Modal>
  )
}
