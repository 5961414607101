import { googleMapApiKey } from 'config'
import GoogleMapReact, { Position } from 'google-map-react'
import { useMemo, useState } from 'react'
import { getBounds } from 'utils'

type MarkerData = Position & { id?: number; title: string }

const Marker = (props: any) => {
  const handleClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <img
      src="/pin.png"
      style={{ width: 32, height: 32, marginLeft: -16, marginTop: -32 }}
      className="cursor-pointer"
      onClick={handleClick}
    />
  )
}

export function GoogleMap({ markers = [], onClick = () => {} }: { markers?: MarkerData[]; onClick?: Function }) {
  const [map, setMap] = useState<any>(null)
  const [key, setKey] = useState(0)

  const updateBounds = (map: any, markers: MarkerData[]) => {
    if (!markers.length) return
    const bounds = new (window as any).google.maps.LatLngBounds()
    markers.map((marker) => {
      bounds.extend({
        lat: marker.lat,
        lng: marker.lng,
      })
    })
    map.fitBounds(bounds)
  }

  const defaultProps = useMemo(() => {
    if (map) updateBounds(map, markers)

    if (!markers.length)
      return {
        center: {
          lat: 41.850033,
          lng: -87.6500523,
        },
        zoom: 4,
      }
    if (!key) setKey(Date.now())
    return getBounds(markers)
  }, [markers])

  const renderMarkers = ({ map }: { map: any; maps: any }) => {
    setMap(map)
    updateBounds(map, markers)
  }

  return (
    <div className="w-full h-full">
      <GoogleMapReact
        key={key}
        options={{ maxZoom: markers.length === 1 ? 15 : undefined }}
        bootstrapURLKeys={{ key: googleMapApiKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={renderMarkers}
      >
        {markers.map(({ lat, lng, id, title }, index) => {
          return <Marker key={index} lat={lat} lng={lng} text={id} tooltip={title} onClick={() => onClick(id)} />
        })}
      </GoogleMapReact>
    </div>
  )
}
