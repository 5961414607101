import { AboutUsPage, FaqPage, HowItWorksPage, PrivacyPolicyPage, TermsOfServicePage } from 'pages/Base'
import { BlogPage } from 'pages/Base/blog'
import { HomePage } from 'pages/Home'
import { MarketplacePage } from 'pages/Marketplace'
import { Navigate, Route, Routes } from 'react-router-dom'

export const BasePages = () => {
  // const { isAuthenticated } = useSelector((state: RootState) => state.auth)

  return (
    <>
      <Route path="/blog/:slug" element={<BlogPage />} />
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/faq" element={<FaqPage />} />
      {/* {!isAuthenticated && <Route path="/lenders" element={<LendersPage />} />} */}
      <Route path="/how_it_works" element={<HowItWorksPage />} />
      <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms_of_service" element={<TermsOfServicePage />} />
    </>
  )
}

export const BaseRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/home" element={<Navigate replace to="/" />} />
    <Route path="/opportunities" element={<MarketplacePage />} />
    {BasePages()}
    <Route path="*" element={<Navigate replace to="/" />} />
  </Routes>
)
