import {
  API_ADD_ARTICLE,
  API_DELETE_ARTICLE,
  API_GET_ALL_ARTICLES,
  API_GET_ARTICLES_FILTER,
  API_GET_BLOG_ID,
  API_GET_BLOG_SLUG,
  API_GET_LAST_ARTICLES,
  API_UPDATE_ARTICLE,
} from 'config'
import Api from 'services/api'

export const getAllArticles = () => {
  return Api.get(API_GET_ALL_ARTICLES)
}

export const getLastArticles = () => {
  return Api.get(API_GET_LAST_ARTICLES)
}

export const getArticles = (filters: Record<string, any>) => {
  return Api.get(API_GET_ARTICLES_FILTER, filters)
}

export const getBlogById = (id: number) => {
  return Api.get(API_GET_BLOG_ID, {}, { id })
}

export const getBlogBySlug = (slug: string) => {
  return Api.get(API_GET_BLOG_SLUG, {}, { slug })
}

export const createArticle = (data: Record<string, any>) => {
  return Api.uploadFiles(API_ADD_ARTICLE, data, {}, [])
}

export const updateArticle = (id: number, data: Record<string, any>) => {
  return Api.uploadFiles(API_UPDATE_ARTICLE, data, { id }, [])
}

export const deleteArticle = (id: number) => {
  return Api.delete(API_DELETE_ARTICLE, {}, { id })
}
