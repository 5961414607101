export enum NotificationType {
  Auth,
  Payment,
  Invest,
  Profit,
  KYC,
  InvestReady,
  TaxCert,
}

export const NotificationTypeMap: Record<NotificationType, string> = {
  [NotificationType.Auth]: 'Authenticate',
  [NotificationType.Payment]: 'Payment',
  [NotificationType.Invest]: 'Invest',
  [NotificationType.Profit]: 'Distribution',
  [NotificationType.KYC]: 'KYC',
  [NotificationType.InvestReady]: 'Accredited',
  [NotificationType.TaxCert]: 'Form W-8',
}

export interface DbNotification {
  id: number
  type: NotificationType
  value: string
  isRead: boolean
  createdAt: Date
}
