export const baseUrl = process.env.REACT_APP_API_URL
export const isAppDev = process.env.REACT_APP_DEV == 'true'

/**
 * Base
 */
export const API_BASE_SUBMIT_USER = '/home/submitUser'
export const API_SETTING = '/setting/:key'

export const API_INVESTREADY_FORM_BY_TYPE = '/investReady/formByType/:type'
export const API_INVESTREADY_FORM_BY_ID = '/investReady/formById/:id'

export const API_FAQ = '/home/faq'
export const API_FAQ_BY_ID = '/admin/faq/:id'
export const API_FAQ_EXCHANGE_ORDER = '/admin/faq/order/:id1/:id2'

/**
 * Authentication
 */
export const API_USER_SIGN_IN = '/auth/login'
export const API_USER_SIGN_UP = '/auth/register'
export const API_USER_FORGET_PASSWORD = '/auth/forgetPassword'
export const API_USER_CHECK_PASSPORT = '/auth/checkPassport/:selector/:token'
export const API_USER_RESET_PASSWORD = '/auth/resetPassword/:selector/:token'
export const API_USER_CONFIRM_USER_EMAIL = '/auth/confirmEmail/:selector/:token'
export const API_USER_VALIDATE_EMAIL = '/auth/validateEmail'
export const API_USER_VALIDATE_PHONE = '/auth/validatePhone'
export const API_USER_SIGN_UP_WITH_PHONE = '/auth/registerWithPhone'
export const API_USER_SIGN_UP_FINISH = '/self/finishSignUp'

export const API_USER_PASSWORD = '/self/password'
export const API_USER_PROFILE = '/self/profile'
export const API_USER_NOTIFICATIONS = '/self/notifications'
export const API_USER_SIGN_SUB_AGREEMENT = '/self/signSubAgreement'
export const API_USER_SIGN_SUB_AGREEMENT_BY_TYPE = '/self/signSubAgreement/:type'
export const API_USER_CONFIRM_SELF_EMAIL = '/self/sendConfirmEmail'

export const API_USER_INVESTREADY = '/self/investReady'
export const API_USER_INVESTREADY_EXPIRES_ON = '/self/investReady/expiresOn'
export const API_USER_INVESTREADY_BY_ID = '/self/investReady/:id'

export const API_USER_TAX_CERT = '/self/taxCert'

export const API_SELF_IDENTITY_VERIFICATION = '/self/identityVerification'
export const API_SELF_IDENTITY_VERIFICATION_DETAILS = '/self/identityVerificationDetails'
export const API_SELF_PLAID_ACCOUNTS = '/self/accounts'

export const API_SELF_DOCUMENTS = '/self/documents'

/**
 * Documents
 */
export const API_DOCS_DOWNLOAD = '/documents/download'
export const API_DOCS_MULTI_DOWNLOAD = '/documents/multiDownload'
export const API_DOCS_UPLOAD = '/documents/upload'

/**
 * Loans
 */
export const API_MARKETPLACE_FILTER_OPTIONS = '/marketplaceFilterOptions'
export const API_LOANS = '/loans/:isAuth'
export const API_LOAN = '/loan/:id'
export const API_LOAN_COMMERCIAL = '/loan/commercial'
export const API_LOAN_COMMERCIAL_TRUSTLEDGER = '/loan/commercial/trustLedger/:loanNumber'
export const API_LOANS_FEATURED = '/loan/featured'
export const API_LOAN_LOG = '/loan/log/:key'
export const API_LOAN_NEXTRES_DOCUMENTS = '/loan/:loanNumber/documents'
export const API_LOAN_FCI = '/loan/:id/fci'

/**
 * Investment
 */
export const API_INVEST = '/invest/all/:userId'
export const API_INVEST_BY_ID = '/invest/:id'
export const API_RETURN_FUNDS_FOR_PAID_OFF = '/invest/returnFundsForPaidOff/:id'
export const API_INVESTORS_DETAIL_BY_LOANID = '/invest/investors/:loanId'
export const API_INVEST_SIGN = '/invest/sign/:loanId'
export const API_INVEST_ENVELOPE = '/invest/envelope/:loanId'
export const API_INVEST_ENVELOPE_URL = '/invest/envelopeUrl/:loanId/:envelopeId'
export const API_INVEST_UNSIGNED_AGREEMENT = '/invest/unsignedAgreement'
export const API_INVEST_SELL_BACK = '/invest/sellBack'
export const API_INVEST_DECLINE = '/invest/decline/:id'
export const API_INVEST_UPDATE_TRANSFER_DATE = '/invest/updateDateTransfer/:id'

/**
 * Users
 */
export const API_USERS = '/user'
export const API_USER_BY_ID = '/user/:id'
export const API_USER_INVEST_READY_BY_ID = '/user/investReady/:id'
export const API_USER_INVEST_READY_EXPIRES_ON_BY_ID = '/user/investReady/expiresOn/:id'
export const API_NOTIFICATION = '/user/notification'
export const API_USER_TAX_CERT_BY_ID = '/user/taxCert/:id'
export const API_USER_IDENTITY_VERIFICATION_DATA = '/user/identityVerification/:id'

/**
 * Payments
 */
export const API_PAY_PLAID_CLIENT_TOKEN = '/payment/plaid/clientToken/:type'
export const API_PAY_PLAID_INSTITUTION = '/payment/plaid/institution/:routingNumber'
export const API_PAY_WIRE_TRANSFER = '/payment/wire/:direction'
export const API_PAY_PLAID_TRANSFER = '/payment/plaid/transfer'

// export const API_PAY_TRANSACTION = '/payment/transaction'
export const API_PAY_PROFIT = '/payment/profit'
export const API_CHECK_REPORT = '/payment/report/check'
export const API_PAY_PROFIT_OVERVIEW = '/payment/profit/overview/:userId'

export const API_PAY_HISTORY = '/payment/history'

export const API_PAY_EARNINGS = '/payment/earnings/:year'
export const API_PAY_MONTH_ESTIMATE = '/payment/earnings/thisMonthEstimate/:userId'

export const API_PAY_ALL_TRANSACTION = '/payment/allTransaction'
export const API_PAY_TRANSACTION_BYID = '/payment/transaction/:id'

export const API_PAY_MANUAL_TRANSACTION_BYID = '/payment/manualTransaction/:id'

export const API_PAY_ALL_PROFIT = '/payment/allProfit'
export const API_PAY_PROFIT_BYID = '/payment/profit/:id'
export const API_APPROVE_SELECTED_DISTRIBUTION = '/payment/profit/approveSelected'
export const API_PAY_PROFITS_BY_INVESTID = '/payment/profits/:loanId/:investId'

export const API_PAY_BALANCE = '/payment/balance/:userId'

/**
 * Admin
 */
export const API_ADMIN_STATISTICS = '/admin/statistics'
export const API_ADMIN_STATISTICS_SUMMARY = '/admin/statistics/summary'
export const API_ADMIN_STATISTICS_PAY = '/admin/statistics/payment'
export const API_ADMIN_STATISTICS_BALANCE = '/admin/statistics/balance'
export const API_ADMIN_BALANCE_BLOCK = '/admin/balance/:userId'
export const API_ADMIN_BALANCE = '/admin/balances'
export const API_ADMIN_BALANCE_SUM = '/admin/balances/sum'
export const API_EMAIL_BROADCAST = '/admin/broadcastEmail'
export const API_ADMIN_TASKS = '/admin/task'
export const API_ADMIN_TASK = '/admin/task/:id'
export const API_ADMIN_TASK_STATUS = '/admin/task/status/:id'
export const API_ADMIN_EMAILS = '/user/admins'
export const API_ADMIN_RESOURCES = '/admin/resources'
export const API_ADMIN_LATEST_RESOURCES = '/admin/resources/latest'
export const API_ADMIN_RESOURCE = '/admin/resource/:id'
export const API_ADMIN_ADD_RESOURCE = '/admin/resource/new'

/**
 * Article
 */

export const API_GET_ALL_ARTICLES = '/articles/all'
export const API_GET_LAST_ARTICLES = '/articles/last'
export const API_GET_ARTICLES_FILTER = '/articles'
export const API_ADD_ARTICLE = '/articles'
export const API_UPDATE_ARTICLE = '/articles/:id'
export const API_DELETE_ARTICLE = '/articles/:id'
export const API_GET_BLOG_ID = '/articles/blog/:id'
export const API_GET_BLOG_SLUG = '/articles/:slug'
