import { authUpdateProfile } from 'actions'
import { Loading } from 'components/Loading'
import { AccountStatus } from 'config'
import { ProfileMenuTitles } from 'pages/Profile'
import { IdentityVerificationStatus } from 'plaid'
import { useEffect, useState } from 'react'
import { PlaidLink } from 'react-plaid-link'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { getPlaidClientToken, getPlaidIdentityVerification, updatePlaidIdentityVerification } from 'services/apis'
import { Button } from 'stories/components'
import { useTitle } from 'utils'

export const KYCPage = () => {
  useTitle('KYC Verification')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const profile = useSelector((state: RootState) => state.auth.profile)
  const [isLoading, setLoading] = useState(true)
  const [clientToken, setClientToken] = useState('')
  const [status, setStatus] = useState<IdentityVerificationStatus | null>(null)

  useEffect(() => {
    if (profile && profile.status < AccountStatus.ProfileCompleted) {
      toast('Please complete your profile.', { type: 'warning' })
      navigate('/profile')
      return
    }

    getPlaidIdentityVerification()
      .then(({ status }) => {
        setStatus(status)
        if (status == IdentityVerificationStatus.Success) onAfterComplete('/marketplace')
        if (![IdentityVerificationStatus.Success, IdentityVerificationStatus.PendingReview].includes(status))
          updateToken()
      })
      .catch(() => setLoading(false))
  }, [])

  const updateToken = () => {
    getPlaidClientToken('kyc')
      .then(({ link_token }) => setClientToken(link_token))
      .finally(() => setLoading(false))
  }

  const onAfterComplete = (url: string) => {
    dispatch(
      authUpdateProfile({
        ...profile,
        status: AccountStatus.KycPassed,
      }),
    )
    navigate(url)
  }

  const onUpdateStatus = (link_session_id: string, publicToken: string | null) => {
    setLoading(true)
    updatePlaidIdentityVerification(link_session_id, publicToken)
      .then(({ status }) => {
        setStatus(status)
        dispatch(
          authUpdateProfile({
            ...profile,
            status:
              status == IdentityVerificationStatus.Success ? AccountStatus.KycPassed : AccountStatus.ProfileCompleted,
          }),
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderStatus = () => {
    switch (status) {
      case IdentityVerificationStatus.Active:
        return (
          <p className="border border-blue-600 bg-blue-50 text-blue-700 p-4 rounded-lg">
            You have an active identity verification now. Please complete it.
          </p>
        )
      case IdentityVerificationStatus.PendingReview:
        return (
          <p className="border border-yellow-600 bg-yellow-50 text-yellow-700 p-4 rounded-lg">
            Your identity verification is under review. We will let you know on email. Thank you.
          </p>
        )
      case IdentityVerificationStatus.Success:
        return (
          <>
            <p className="border border-green-600 bg-green-50 text-green-700 p-4 rounded-lg mb-4">
              Thank you for verifying your identity.
            </p>
            <Button
              color="green"
              className="max-w-xs mx-auto"
              onClick={() => onAfterComplete(`/profile#${ProfileMenuTitles.INVEST_READY}`)}
            >
              Continue
            </Button>
          </>
        )
      case IdentityVerificationStatus.Failed:
      case IdentityVerificationStatus.Expired:
      case IdentityVerificationStatus.Canceled:
        return (
          <p className="border border-yellow-600 bg-yellow-50 text-yellow-700 p-4 rounded-lg">
            Your identity verification {status}. Please contact support at
            <span className="text-link ml-1 whitespace-nowrap">info@finresi.com</span>
          </p>
        )
    }
  }

  return (
    <div className="w-full relative py-4 md:py-10">
      <div className="max-w-screen-lg mx-auto relative mt-10 text-center">
        <div className="max-w-screen-md mx-auto min-h-[30vh]">
          <p className="uppercase text-sm mb-2">Identity Verification</p>
          <p className="text-lg mb-4">
            Please complete your verification to become eligible to invest on the Finresi platform.
          </p>
        </div>

        <div className="py-10">
          <div className="text-center mb-4">{renderStatus()}</div>

          {clientToken && status != IdentityVerificationStatus.Success ? (
            <PlaidLink
              token={clientToken}
              onLoad={() => {
                setLoading(false)
                console.log('onLoad')
              }}
              onSuccess={(public_token, metadata) => {
                const { link_session_id } = metadata
                onUpdateStatus(link_session_id, public_token)
              }}
              onEvent={(event, metadata) => {
                if (metadata && metadata.error_message) toast(metadata.error_message, { type: 'error' })
              }}
              onExit={(error, metadata) => {
                console.log('exit', error, metadata)
                const { link_session_id } = metadata
                onUpdateStatus(link_session_id, null)
              }}
              style={{
                background: undefined,
                padding: undefined,
                outline: undefined,
                border: undefined,
                borderRadius: undefined,
              }}
              className="w-full"
            >
              <Button color="green" loading={isLoading} isDiv className="max-w-xs mx-auto">
                Start Verification
              </Button>
            </PlaidLink>
          ) : (
            !status && (
              <div className="flex justify-center">
                <Loading />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}
