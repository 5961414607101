import { InformationCircleIcon, ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline'
import { authUpdateProfile } from 'actions'
import { AccountStatus, CustomInput, fullStates, InputType, isArticleManager, isManager, states } from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { accountTypeOptions } from 'pages/Auth/SignUp/AccountType'
// import { investorProfileInputs } from 'pages/Auth/SignUp/InvestorProfile'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { getProfile, sendConfirmEmailToSelf, updateProfile } from 'services/apis'
import { Button, Checkbox } from 'stories/components'
import { countries } from 'stories/components/Input/PhoneSelector'
import { InputConvert, RenderInput, useTitle, validateInputs } from 'utils'

import { ProfileMenuTitles } from './Profile'

export const AccountDetails = () => {
  useTitle('Account Information')

  const [isLoading, setLoading] = useState(false)
  const [sentEmail, setSentEmail] = useState(false)
  const profile = useSelector((state: RootState) => state.auth.profile)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const baseData = useMemo<Record<string, any>>(
    () => ({
      'Name:': profile.name,
      'Phone:': profile.phone,
      'Accredited Investor?': profile.isAccreditedInvestor == '1' ? 'Yes' : 'No',
      'Foreign Investor?': profile.isForeignInvestor == '1' ? 'Yes' : 'No',
      'Email:': profile.email,
      'Country:': countries[profile.country],
      'State:': profile.accountDetails.state ? fullStates[profile.accountDetails.state] : '',
      'City:': profile.accountDetails.city,
    }),
    [profile],
  )

  const { accountType } = profile
  const isAdmin = isManager(accountType)
  const isArticle = isArticleManager(accountType)
  // const isUpdatable = profile.status == AccountStatus.EmailVerified
  const isEmailVerified = profile.status >= AccountStatus.EmailVerified

  const renderAccountType = (value: string, onChange: Function, input: CustomInput) => {
    const { error } = input

    return (
      <div className="mb-8">
        <p className="text-gray-400 mb-2">Please select one:</p>
        <div className="flex flex-col gap-2">
          {Object.keys(accountTypeOptions).map((key) => {
            const options = accountTypeOptions[key]
            const isActive = value == key

            return (
              <div className={`border rounded-md p-2 ${isActive ? 'bg-indigo-500' : ''}`} key={key}>
                <Checkbox
                  id={key}
                  checked={isActive}
                  onClick={() => !isActive && onChange(key)}
                  title={
                    <div>
                      <p className={isActive ? 'text-white' : ''}>{options.title}</p>
                      <p className={`text-sm ${isActive ? 'text-gray-100' : 'text-gray-600'}`}>{options.description}</p>
                    </div>
                  }
                />
              </div>
            )
          })}
        </div>
        {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1 mt-1">{error}</p>}
      </div>
    )
  }

  const [inputs, setInputs] = useState<Record<string, Record<string, InputType>>>({
    accountDetails: {
      // ...accountDetailsInputs(accountType, true),
      accountType: {
        inputType: 'custom',
        title: '',
        render: renderAccountType,
        required: true,
        value: profile.accountType,
      },
    },
    // investorProfile: investorProfileInputs(),
  })

  useEffect(() => {
    setLoading(true)
    getProfile()
      .then((profile) => dispatch(authUpdateProfile(profile)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    // 'investorProfile'
    ;['accountDetails'].forEach((section) =>
      Object.keys((profile as any)[section]).forEach((key) => {
        if (newInputs[section][key]) newInputs[section][key].value = (profile as any)[section][key]
      }),
    )
    // ;(newInputs.accountDetails.accountType as any).options = NormalAccountTypes
    newInputs.accountDetails.accountType = {
      inputType: 'custom',
      title: '',
      render: renderAccountType,
      required: true,
      value: profile.accountType,
    }
    setInputs(newInputs)
  }, [profile])

  const onChange = (section: string, key: string, value: string) => {
    // if (!isUpdatable) return

    let newState = cloneDeep(inputs)
    newState[section][key].value = InputConvert(newState[section][key], value)
    newState[section][key].error = ''

    if (section == 'accountDetails' && key == 'accountType') {
      if (value == inputs[section][key].value) return

      const detailsInputs: Record<string, InputType> = {}
      detailsInputs.accountType = {
        inputType: 'custom',
        title: '',
        render: renderAccountType,
        value,
        required: true,
      }

      Object.keys(detailsInputs).forEach((key) => {
        if (!newState['accountDetails'][key]) return
        detailsInputs[key].value = newState['accountDetails'][key].value
      })
      // ;(detailsInputs.accountType as any).options = NormalAccountTypes
      newState['accountDetails'] = detailsInputs
    }

    setInputs(newState)
  }

  const onSubmit = async () => {
    const {
      hasError: accountHasError,
      data: accountDetails,
      newInputStates: accountDetailsStates,
    } = validateInputs(inputs['accountDetails'])
    // const {
    //   hasError: investorHasError,
    //   data: investorProfile,
    //   newInputStates: investorProfileStates,
    // } = validateInputs(inputs['investorProfile'])

    if (accountHasError) {
      // || investorHasError) {
      setInputs({
        ...inputs,
        accountDetails: accountDetailsStates,
        // investorProfile: investorProfileStates,
      })
      return
    }

    // const result = await confirm(
    //   <p className="text-base mb-4">
    //     Once you have submitted your investor profile, please note that it cannot be edited. It is crucial to ensure
    //     that all information provided during the submission process is accurate.
    //   </p>,
    //   {
    //     buttons: {
    //       ok: {
    //         className: 'text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-indigo-300',
    //         title: 'Continue',
    //       },
    //       cancel: {
    //         className: 'text-gray-500 bg-white hover:bg-gray-100',
    //         title: 'Cancel',
    //       },
    //     },
    //   },
    // )
    // if (!result) return

    setLoading(true)
    const newAccountDetails: Record<string, any> = {
      ...profile.accountDetails,
      ...accountDetails,
    }

    Object.keys(newAccountDetails).forEach((key) => {
      if (typeof newAccountDetails[key] != 'boolean' || key == 'isFundAccredit') return
      newAccountDetails[key] = newAccountDetails[key] ? '1' : '0'
    })

    updateProfile({ accountType: accountDetails.accountType, accountDetails: newAccountDetails, investorProfile: {} })
      .then(() => {
        const isProfileCompleted = profile.status == AccountStatus.ProfileCompleted
        toast(
          isProfileCompleted
            ? 'Your profile has been updated.'
            : 'Congratulations, your account information has been complete, please continue to the identity verification.',
          {
            type: 'success',
          },
        )

        dispatch(
          authUpdateProfile({
            ...profile,
            accountType: accountDetails.accountType,
            // accountDetails: newAccountDetails as any,
            // investorProfile: investorProfile as any,
            status: isProfileCompleted ? profile.status : AccountStatus.ProfileCompleted,
          }),
        )
      })
      .finally(() => {
        setLoading(false)
        navigate(`/profile#${ProfileMenuTitles.KYC}`)
      })
  }

  const onSendConfirmEmail = () => {
    setLoading(true)
    sendConfirmEmailToSelf()
      .then(() => {
        toast('A confirmation email has been sent.', { type: 'info' })
        setSentEmail(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="text-base text-gray-600">
      <div className="pb-4 mb-4">
        {Object.keys(baseData).map((key) => (
          <div className="grid grid-cols-7 gap-2 items-center my-2" key={key}>
            <p className="col-span-2">{key}</p>

            <div className="flex items-center flex-wrap gap-2 col-span-5 break-all">
              <p className={`font-medium ${key != 'Email:' && 'capitalize'} italic`}>{baseData[key]}</p>

              {key === 'Phone:' && <ShieldCheckIcon className="w-5 h-5 text-lime-500" />}

              {key === 'Email:' && isEmailVerified ? (
                <ShieldCheckIcon className="w-5 h-5 text-lime-500" />
              ) : key === 'Email:' ? (
                <ShieldExclamationIcon className="w-5 h-5 text-red-500" />
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}

        {!isEmailVerified && sentEmail && (
          <div className="grid grid-cols-7 gap-2">
            <div className="col-span-2"></div>
            <div className="col-span-5">
              <div className="text-yellow-500 text-sm">
                We have just sent a verification email. Please check your email to verify. If you do not see the email
                in your inbox, please check your spam or junk folder.
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-2">
          {/* <div /> */}
          {!isAdmin && !isArticle && (
            <div className="">
              <p className="font-bold border-b-2 mb-2">Account Type</p>
              {RenderInput({
                input: inputs.accountDetails.accountType,
                Key: 'accountType',
                onChange: (key: string, v: any) => onChange('accountDetails', 'accountType', v),
              })}
            </div>
          )}
        </div>

        {!isEmailVerified && (
          <div className="flex justify-center">
            <div className="border border-yellow-500 bg-yellow-50 text-yellow-500 text-center mt-4 p-3 rounded min-w-[300px] w-1/2">
              Please verify your email before continuing.
            </div>
          </div>
        )}

        {isEmailVerified && !isArticle && (
          <div className="mt-4 w-full">
            <div className="w-fit mx-auto">
              <Button onClick={onSubmit} loading={isLoading} full>
                Continue account set up
              </Button>
            </div>
          </div>
        )}

        {/* {!isUpdatable && <div className="absolute w-full top-0 left-0 bottom-0 bg-white/20 z-[9999]" />} */}
      </div>

      {!isEmailVerified && (
        <div className="mt-4 w-full">
          <div className="w-60 mx-auto">
            <Button full loading={isLoading} onClick={onSendConfirmEmail}>
              {sentEmail ? 'Resend' : 'Verify Now'}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export const accreditedTooltipContent = (
  <div className="w-[80vw] xs:w-96 relative">
    <p className="mb-2">Do you have any of one of these requirements?</p>
    {[
      'An annual income of at least $200,000 for the past two years (or $300,000 with a spouse).',
      'Net worth exceeding $1 million, excluding primary residence.',
      'Hold a professional designation, like a Series 7 or Series 65 license.',
    ].map((text, index) => (
      <p key={index}>
        {index + 1}. {text}
      </p>
    ))}
  </div>
)

export const accountBaseInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      title: 'First Name',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
    },
    lastName: {
      title: 'Last Name',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
    },
    phone: {
      title: 'Phone',
      placeholder: '+1 215 888 8888',
      inputType: 'text0',
      type: 'phone',
      required: true,
    },
    email: {
      title: 'Email',
      placeholder: 'name@example.com',
      inputType: 'text0',
      type: 'email',
      error: '',
      required: true,
    },
    country: {
      title: 'Country',
      placeholder: '',
      inputType: 'select0',
      options: countries,
      error: '',
      required: true,
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
    },
    state: {
      title: 'State',
      placeholder: 'Select',
      inputType: 'select0',
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
      options: states,
      error: '',
      required: true,
    },
    city: {
      title: 'City',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
    },
    isAccreditedInvestor: {
      title: (
        <>
          Do you qualify as an accredited investor?
          <Tooltip className="accredited-tooltip" content={accreditedTooltipContent}>
            <InformationCircleIcon className="w-4 h-4 cursor-pointer" />
          </Tooltip>
        </>
      ) as any,
      inputType: 'radio',
      options: { 1: 'Yes', 0: 'No' },
      innerClassName: 'flex-row-reverse justify-end',
      required: true,
      className: 'font-medium text-base mb-4',
    },
    // isForeignInvestor: {
    //   title: (
    //     <p className="flex gap-2 items-center">
    //       Are you a foreign Investor (Non US) ?
    //       {/* <Tooltip className="accredited-tooltip" content={accreditedTooltipContent}>
    //         <InformationCircleIcon className="w-4 h-4 cursor-pointer" />
    //       </Tooltip> */}
    //     </p>
    //   ) as any,
    //   inputType: 'radio',
    //   options: { 1: 'Yes', 0: 'No' },
    //   innerClassName: 'flex-row-reverse justify-end',
    //   required: true,
    //   className: 'font-medium text-base',
    // },
  }
}

// export const _AccountDetails = () => {
//   const [isLoading, setLoading] = useState(false)
//   const [inputs, setInputs] = useState(accountBaseInputs())
//   const profile = useSelector((state: RootState) => state.auth.profile)
//   const dispatch = useDispatch()

//   useEffect(() => {
//     const newInputs = cloneDeep(inputs)
//     newInputs.firstName.value = firstName(profile.name)
//     newInputs.lastName.value = lastName(profile.name)
//     newInputs.email.value = profile.email
//     newInputs.email.disabled = true
//     setInputs(newInputs)
//   }, [profile])

//   const onChange = (key: string, value: string) => {
//     let newState = cloneDeep(inputs)
//     newState[key].value = InputConvert(newState[key], value)
//     newState[key].error = ''
//     setInputs(newState)
//   }

//   const onSubmit = () => {
//     const { hasError, data, newInputStates } = validateInputs(inputs)
//     if (hasError) {
//       setInputs(newInputStates)
//       return
//     }
//     delete data.email

//     data.name = `${data.firstName} ${data.lastName}`
//     delete data.firstName
//     delete data.lastName

//     setLoading(true)
//     updateProfile(data)
//       .then(() => {
//         toast('Account Details have been updated successfully.', { type: 'success' })
//         dispatch(
//           authUpdateProfile({
//             ...profile,
//             ...data,
//           }),
//         )
//       })
//       .finally(() => setLoading(false))
//   }

//   return (
//     <div className="max-w-md text-sm">
//       {Object.keys(inputs).map((key) => {
//         const input = inputs[key]
//         return <RenderInput Key={key} input={input} onChange={onChange} key={key} />
//       })}
//       <Button onClick={onSubmit} loading={isLoading} full>
//         Update
//       </Button>
//     </div>
//   )
// }
