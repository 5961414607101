import type { SignUpSteps } from 'pages/Auth'

export enum AccountType {
  Individual = 'individual',
  Ira = 'ira',
  Trust = 'trust',
  Company = 'company',
  Admin = 'admin',
  ArticleManager = 'articleManager',
  FundingManager = 'fundingManager',
  OperationAdmin = 'operationAdmin',
}

export const AccountTypeText: Record<string, string> = {
  individual: 'Individual',
  ira: 'Ira',
  trust: 'Trust',
  company: 'Entity',
  admin: 'Admin',
  articleManager: 'Article Manager',
  fundingManager: 'Funding Manager',
  operationAdmin: 'Operation Admin',
}

export const NormalAccountTypes: Record<string, string> = {
  individual: 'Individual',
  ira: 'Ira',
  trust: 'Trust',
  company: 'Entity',
}

export enum AccountStatus {
  Deactive,
  Pending,
  EmailVerified,
  ProfileCompleted,
  KycPassed,
  InvestreadyVerified, // Not Used
  TaxCert, // Not Used
  AccreditedVerified,
}

export const AccountFilterStatusText: Record<string, number> = {
  Deactive: 0,
  Pending: 1,
  EmailVerified: 2,
  ProfileCompleted: 3,
  KycPassed: 4,
  AccreditedVerified: 5,
}

export const AccountStatusText = {
  [AccountStatus.Deactive]: 'Deactive',
  [AccountStatus.Pending]: 'Pending',
  [AccountStatus.EmailVerified]: 'Email Verified',
  [AccountStatus.ProfileCompleted]: 'Profile Completed',
  [AccountStatus.KycPassed]: 'Kyc Passed',
}

export interface AccountInvestorProfile {
  bonds: string
  stocks: string
  mutualFunds: string
  riskTolerance: string
  investmentObjective: string
}

// export interface IRADetails {
//   isResident: string
//   isIndividualIncome: string
//   isIndividualExpectIncome: string
//   isCurrentNetWorth: string
//   isHoldSeries: string
// }

export interface IndividualDetails {
  // extends IRADetails {
  formOfOwnership: string
}

export interface TrustDetails {
  legalName: string
  // state: string
  // trusteeIncome: string
  // income2021: number
  // income2022: number
  // income2023: number
  // netWorth: string
  // isHoldSeries: string
  // typeOfTrust: string
  // typeOfGrantor: string
  // isFundMore5m: boolean
}

export interface CompanyDetails {
  legalName: string
  companyType: string
  // state: string
  isFundAccredit: boolean
}

export type AccountDetails = (IndividualDetails | TrustDetails | CompanyDetails) & {
  state: string
  city: string
  authStep: SignUpSteps

  // isIndividualIncome: '0' | '1'
  // isCurrentNetWorth: '0' | '1'
  // isHoldSeries: '0' | '1'

  netWorth: string
  investmentRange: string
}

export interface AccountProfile {
  id: number
  name: string
  phone: string
  isAccreditedInvestor: string
  isForeignInvestor: string
  email: string

  accountType: AccountType
  accountDetails: AccountDetails
  companyDetails: Record<string, any>
  // investorProfile: AccountInvestorProfile
  status: AccountStatus
  deleted: boolean
  isResetPassword: boolean
  subAgreementAt: Date
  subAgreement: string
  accreditedExpiresOn: Date | null
  country: string
  taxCertPassed: boolean

  createdAt: string
}

export const ManagerAccountTypes = [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin]

export const isManager = (type: AccountType) => ManagerAccountTypes.includes(type)

export const isTopManager = (type: AccountType) => [AccountType.Admin, AccountType.FundingManager].includes(type)

export const isArticleManager = (type: AccountType) => [AccountType.ArticleManager].includes(type)
